export const JP = {
  home: 'ホーム',
  about: '会社概要',
  technology: '技術',
  products: '製品',
  SignUp: 'サインアップ',
  freeMint: 'フリーミント',
  connect: 'サインアップ',
  homePage: {
    content1: '包括的なWeb3スマートホームシステム',
    content2:
      'グローバルな新しい体験です。私たちは本当にWeb3の世界で構築されたシナリオを世界中の何千もの家庭にもたらします。'
  },
  DIDowners: 'DIDオーナー',
  ActiveUsers: 'アクティブユーザー',
  SmarthomeInstalled: 'インストールされたスマートホーム',
  aboutPage: {
    content1:
      'スタートアップから業界リーダーへ - DomusAlはスマートホーム業界のイノベーションの最前線に立ってきました。',
    content2:
      '技術革新に取り組み、安全性と生活の品質の世界基準を向上させています。優れたエンジニア、デザイナー、リーダーからなるチームは私たちの成功の基盤です。',
    title1: '主な特徴',
    desc1:
      'セキュリティと知能の融合 - DomusAlはWeb3テクノロジーを統合し、データの漏洩から保護します。また、私たちのAIシステムはあなたの生活習慣を学習し、自動的に家庭デバイスを調整し、個別のニーズに対応します。',
    title2: '技術で未来を創造する',
    desc2:
      'DomusAlは人工知能とブロックチェーン技術の力を結集し、先進的なスマートホームエコシステムを構築しています。このシステムでは人工知能が脳となり、ブロックチェーンがフレームワークとなり、スマートホームの発展にさらなる可能性と活力を提供します。'
  },
  technologyPage: {
    title: '技術情報',
    text1: 'WEB3+Al スマートシステム',
    text2: '興味とプライバシーの識別技術',
    text3: '分散プロトコル・コンソール',
    text4: '統一分散通信プロトコル',
    text5: '安全なデータストレージ',
    text6: 'スマートネットワークアーキテクチャ'
  },
  freeMintPage: {
    title: 'AI + ホームソリューション',
    content1:
      '私たちに参加して、安全でプライベートなデジタルIDを体験するために、無料であなたの分散型IDをミントしてください。',
    content2: '今すぐサインアップして、ID認証の未来を探求しましょう！',
    comprehensive: '包括的な',
    smarthome: 'スマートホーム',
    products: 'の提供',
    desc: 'DomusAlは人工知能とビッグデータを利用して、パーソナライズされたスマートホーム・サービスを提供する。 ユーザーの習慣や好みを分析することで、スマートホームデバイスの設定やモードをインテリジェントに調整し、オーダーメイドのスマートリビング体験を提供する。'
  },
  ecosystem: 'エコシステム',
  foot: 'アートな住まいの新時代をリードする',
  pageDID: {
    mint: 'コネクトウォレット',
    welcome: 'へようこそ',
    content1: '全館Web3スマートシステム、アーティスティックホー',
    content2: 'ム時代の新しいナビゲーター！',
    content3: 'DomusAl DlD mintに参加して、次世代のWeb3スマートホーム技術を世界に広めましょう。',
    content4: '$500 Million',
    content5: 'DOMの賞金プール',
    freeMint: 'フリーミント',
    text1: 'あなたの DomusAI DID',
    FrennMint: 'フリーミント',
    price: '価格',
    free: '無料！',
    amount: '額',
    introduction: {
      title: 'DIDの紹介',
      content:
        'DomusAI DIDの正式名称はDecentralized Home IDで、スマートホームAIシステム向けに特別に設計された画期的なコンセプトであり、ユニークな本人確認ソリューションを提供する。この分散型ID技術は、すべてのスマートホームシステムに固有のデジタルIDを割り当て、相互運用性、セキュリティ、デバイス間のパーソナライズされたサービスの提供を強化する。',
      subtitle: 'ホームDIDの中核機能',
      list: {
        title1: '一意性',
        desc1:
          '各スマートホームシステムは、Home DIDを通じて一意の識別子を取得し、エコシステム全体におけるデータのやり取りやサービス提供の際に、明確で安全なアイデンティティを確保します。',
        title2: '分散化',
        desc2:
          'ブロックチェーン技術を活用することで、Home DIDは中央集権的な管理主体から独立したIDデータの不変性と透明性を確保します。',
        title3: '安全性',
        desc3: `強力な暗号化技術により、ユーザーのプライバシーとデータセキュリティを保護し、不正アクセスやデータ漏洩を防ぎます。`,
        title4: '相互運用性',
        desc4:
          'Home DIDは様々なスマートホームデバイスやサービス間のシームレスな統合をサポートし、デバイスの統合と管理を簡素化し、よりスムーズでパーソナライズされた体験をユーザーに提供します。'
      },
      content2:
        'Home DIDを活用することで、スマートホームシステムは本人確認とデータ交換をより効率的に管理し、ホームセキュリティ、エネルギー管理、インテリジェントリビングをより強力かつ柔軟にサポートすることができます。音声アシスタントによる照明制御や、高度にパーソナライズされた家庭環境調整の実現など、Home DIDはすべての操作の安全性と利便性を保証し、シームレスなスマートライフ体験を真に可能にします。'
    },
    contribution: {
      title: '私の貢献'
    },
    benefits: {
      title: 'DlDのメリット',
      subtitle: `DomusAI DIDユーザーのメリットは以下の通り：`,
      content: `これらの利点は、ユーザーのスマートホーム体験を向上させるだけでなく、システムのセキュリティと信頼性を強化し、ユーザーにスマートホームシステムに対する自信と依存心を高める。この本人確認システムの導入は、スマートホーム技術の将来の方向性を示すものであり、ユーザーにとってより安全で、便利で、インテリジェントな生活環境の創造を目指すものである。`,
      list: {
        title1: 'データプライバシーとセキュリティの強化',
        desc1:
          '당신의DID本人確認システムを通じて、ユーザーはデータのプライバシーとセキュリティが効果的に保護され、データ漏洩や個人情報盗難のリスクを軽減することができます。',
        title2: 'シームレスなクロスデバイス管理',
        desc2:
          'DomusAI DIDを利用することで、ユーザーは1つのプラットフォームからすべてのスマートホームデバイスを管理することができ、デバイスごとに異なるセキュリティプロトコルを設定したり、覚えたりする必要がなくなるため、運用の利便性と効率が向上します。',
        title3: 'パーソナライズされたユーザーエクスペリエンス',
        desc3:
          'DomusAI DIDは、高度にパーソナライズされたサービス設定をサポートし、ユーザーの好みや習慣に基づいてスマートホーム環境（温度、照明、セキュリティ設定など）を自動的に調整し、家庭の快適性を高めます。',
        title4: 'アクセス制御と権限管理',
        desc4:
          'ユーザーはDomusAI DIDを通じてさまざまなアクセス許可を設定し、家族やゲストの特定のデバイスへのアクセス権を制御して、ホーム安全を確保することができます。',
        title5: '迅速な応答と遠隔操作',
        desc5:
          'DomusAI DIDはリアルタイムに応答し、無人の家電製品の電源を切ったり、自宅の温度を調整したり、必要なときにいつでも自宅のスマートデバイスを遠隔操作することができます。',
        title6: '優先カスタマーサポート',
        desc6:
          'DomusAI DIDユーザーは、24時間365日のテクニカルサポートやデバイスの故障に対する迅速な対応など、優先的なカスタマーサポートサービスを受けることができる。',
        title7: '限定イベントとオファー',
        desc7:
          'DomusAI DIDユーザーには、定期的な限定イベント、製品アップデートへの早期アクセス、特別割引を提供し、ユーザーの忠誠心と満足度を高めます。'
      }
    }
  },
  congratulations: 'おめでとうございます!',
  modal1: 'DomusAI DIDを',
  modal2: '個ゲット',
  claim: 'クレーム'
}
