import { useSelector, useDispatch } from 'react-redux'
// import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { useCallback } from 'react'
import store, { AppState, AppDispatch } from '../index'
import { UserInfo, saveUserInfo } from './actions'

export function clearAllSignStoreSync() {
  store.dispatch({
    type: 'localUserInfo/removeUserInfo'
  })
}

export function useUserInfo() {
  const allUserInfo = useSelector((state: AppState) => state.localUserInfo)
  // const { account } = useWeb3ReactCore()
  const dispatch = useDispatch<AppDispatch>()

  const updateUserInfoCallback = useCallback(
    (userInfo: UserInfo) => {
      dispatch(saveUserInfo({ userInfo }))
    },
    [dispatch]
  )

  // const updateUserInfo = useCallback(
  //   (userInfo: UserInfo) => {
  //     updateUserInfoCallback(userInfo)
  //   }
  //   }, [updateUserInfoCallback]
  // )

  const updateUserInfo = useCallback(
    (userInfo: UserInfo) => {
      updateUserInfoCallback(userInfo)
    },
    [updateUserInfoCallback]
  )
  // const updateUserInfo = useCallback(()=>{

  // },data,)
  return {
    updateUserInfo,
    data: allUserInfo.addressInfo
  }
}
