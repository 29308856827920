import React from 'react'
import { ButtonBase, Theme, useTheme } from '@mui/material'
import { SxProps } from '@mui/system'

interface Props {
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void) | (() => void)
  width?: string
  height?: string
  backgroundColor?: string
  borderRadius?: string
  disabled?: boolean
  color?: string
  children?: React.ReactNode
  fontSize?: string | number
  className?: string
  style?: React.CSSProperties & SxProps<Theme>
  active?: boolean
  disableRipple?: boolean
  hoverbg?: string
  borderColor?: string
}

export default function Button(props: Props) {
  const {
    onClick,
    disabled,
    style,
    width,
    height,
    fontSize,
    backgroundColor,
    borderColor,
    color,
    disableRipple,
    className,
    borderRadius,
    hoverbg
  } = props
  const theme = useTheme()
  return (
    <ButtonBase
      disableRipple={disableRipple}
      onClick={onClick}
      disabled={disabled}
      className={className}
      sx={{
        width: width || '100%',
        height: height || 36,
        fontSize: fontSize || 24,
        fontWeight: 700,
        transition: '.3s',
        border: `2px solid  ${borderColor ? borderColor : theme.palette.primary.main}`,
        borderRadius: borderRadius || `${theme.shape.borderRadius}px`,
        background: hoverbg ? 'transparent' : backgroundColor || theme.palette.primary.main,
        color: hoverbg ? '#fff' : color || theme.palette.primary.contrastText,
        '&:hover': {
          background: hoverbg || 'transparent',
          color: hoverbg ? '#000' : '#fff'
        },
        // '&:disabled': {
        //   backgroundColor: theme.palette.primary.light,
        //   border: 0
        // },
        ...style
      }}
    >
      {props.children}
    </ButtonBase>
  )
}

export function BlackButton({ style, ...props }: Props) {
  const theme = useTheme()
  return (
    <Button
      {...props}
      style={{
        background: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.primary.dark
        },
        '&:disabled': {
          backgroundColor: theme.palette.text.disabled
        },
        ...style
      }}
    />
  )
}

export function DefaultButton({ onClick, style, active, ...props }: Props) {
  // const theme = useTheme()
  return (
    <Button
      onClick={onClick}
      {...props}
      style={{
        color: active ? '#000' : '#fff',
        backgroundColor: active ? '#00FF75' : 'transparent',
        borderRadius: '2px',
        padding: '0 8px',
        fontSize: 24,
        fontWeight: 700,
        height: 25,
        '&:hover': {
          background: '#00FF75',
          color: '#000'
        },
        ...style
      }}
    />
  )
}
