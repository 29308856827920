import { Box, Stack, styled } from '@mui/material'
import useBreakpoint from 'hooks/useBreakpoint'
import freeImage from 'assets/images/free_image.png'
import freeBg from 'assets/images/free_bg.png'
import Image from 'components/Image'
import { useI18n } from 'react-simple-i18n'

// import { routes } from 'constants/routes'
const MintBg = styled(Box)(`
background-image: url(${freeBg});
background-size: cover;
background-repeat: no-repeat;
`)

export default function FreeMint() {
  const isSmDown = useBreakpoint('sm')
  const { t } = useI18n()
  return (
    <Box>
      <Box
        padding={isSmDown ? '0 24px' : 0}
        width={isSmDown ? '100%' : { xl: 1440, lg: 1292 }}
        position={'relative'}
        margin={'0 auto'}
        mt={isSmDown ? 80 : 170}
      >
        <Stack
          direction={isSmDown ? 'column' : 'row'}
          justifyContent={'space-between'}
          sx={{
            background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
            border: '1px solid #3F3F3F',
            borderRadius: '20px',
            minHeight: '453px',
            padding: 30
          }}
        >
          <Box
            sx={{
              textAlign: 'left'
            }}
          >
            <Box
              mt={isSmDown ? 0 : 22}
              sx={{
                fontSize: isSmDown ? 32 : 72,
                fontWeight: isSmDown ? 500 : 300,
                background: ' linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                lineHeight: isSmDown ? '48px' : '72px'
              }}
            >
              {t('freeMintPage.comprehensive')}
            </Box>
            <Box
              sx={{
                fontSize: isSmDown ? 32 : 72,
                fontWeight: isSmDown ? 700 : 600,
                lineHeight: isSmDown ? '48px' : '72px',
                color: '#fff'
              }}
            >
              {t('freeMintPage.smarthome')}
            </Box>
            <Box
              sx={{
                fontSize: isSmDown ? 32 : 72,
                fontWeight: isSmDown ? 500 : 300,
                background: ' linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                lineHeight: isSmDown ? '48px' : '72px'
              }}
            >
              {t('freeMintPage.products')}
            </Box>
            <Box
              mt={38}
              mb={isSmDown ? 50 : 0}
              sx={{
                color: '#d3d6df',
                fontSize: 14,
                lineHeight: isSmDown ? '21px' : '24px',
                fontWeight: 400,
                maxWidth: '500px'
              }}
            >
              {t('freeMintPage.desc')}
            </Box>
          </Box>
          {isSmDown ? (
            <Box width={'100%'}>
              <Image width={'100%'} src={freeImage}></Image>
            </Box>
          ) : (
            <Box>
              <Image src={freeImage}></Image>
            </Box>
          )}
        </Stack>
      </Box>
      <MintBg
        id={'Products'}
        height={isSmDown ? 563 : 678}
        sx={{
          marginTop: 0,
          paddingTop: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box padding={isSmDown ? '0px 24px 0px' : 0}>
          <Box
            mt={0}
            mb={33}
            sx={{
              fontSize: isSmDown ? '52px' : '72px',
              fontWeight: 300,
              lineHeight: isSmDown ? '58px' : '72px',
              background: ' linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('freeMintPage.title')}
          </Box>
          <Box
            sx={{
              fontSize: isSmDown ? 14 : 18,
              lineHeight: isSmDown ? '21px' : '27px',
              color: '#fff'
            }}
          >
            {t('freeMintPage.content1')}
          </Box>
          <Box
            sx={{
              fontSize: isSmDown ? 14 : 18,
              lineHeight: isSmDown ? '21px' : '27px',
              color: '#fff'
            }}
          >
            {t('freeMintPage.content2')}
          </Box>
        </Box>
      </MintBg>
    </Box>
  )
}
