import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import Button from 'components/Button/Button'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Box } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useI18n } from 'react-simple-i18n'
import useBreakpoint from 'hooks/useBreakpoint'

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

export default function Lang() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { i18n } = useI18n()
  const isSmDown = useBreakpoint('sm')
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box mr={isSmDown ? 10 : 20}>
      <Button
        color="#fff"
        backgroundColor="transparent"
        fontSize={14}
        width={'70px'}
        height={'32px'}
        onClick={handleClick}
        borderRadius={'10px'}
        borderColor="#1EB2D8"
      >
        <>{i18n.getLang()}</>
        <KeyboardArrowDownIcon />
      </Button>
      <StyledMenu
        sx={{
          '& .MuiPaper-root': {
            width: '68px!important',
            minWidth: 'auto',
            border: '1px solid #1EB2D8',
            borderRadius: '10px'
          },
          '& .MuiMenu-list': {
            backgroundColor: '#000'
          },
          '& .MuiMenuItem-root': {
            color: '#fff',
            fontSize: 14,
            width: 66,
            textAlign: 'center',
            justifyContent: 'center'
          }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            i18n.setLang('EN')
            setAnchorEl(null)
          }}
          disableRipple
        >
          EN
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18n.setLang('KR')
            setAnchorEl(null)
          }}
          disableRipple
        >
          KR
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18n.setLang('JP')
            setAnchorEl(null)
          }}
          disableRipple
        >
          JP
        </MenuItem>
      </StyledMenu>
    </Box>
  )
}
