import { Box } from '@mui/material'
import popBg from 'assets/images/pop_bg.png'
import Image from 'components/Image'
import Modal from '../index'
import Button from 'components/Button/Button'
import useModal from 'hooks/useModal'
import didNftLogo from 'assets/images/did_nft_logo_v2.png'
import { useI18n } from 'react-simple-i18n'

export default function SucceedModal({ num }: { num: string | number }) {
  const { t } = useI18n()
  const { hideModal } = useModal()
  return (
    <Modal isSucceed={true} isCardOnMobile={true}>
      <Box
        sx={{
          position: 'relative'
        }}
        width={'365%px'}
        height={'328px'}
      >
        <Image
          style={{
            display: 'block'
          }}
          width={'100%'}
          height={'100%'}
          src={popBg}
        ></Image>
        <Box
          padding={'30px 24px'}
          textAlign={'center'}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            fontSize={32}
            lineHeight={'48px'}
            fontWeight={600}
            sx={{
              background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('congratulations')}
          </Box>
          <Box fontSize={14} lineHeight={'21px'} fontWeight={600} color={'#fff'}>
            {t('modal1')} {num} {t('modal2')}
          </Box>
          <Box
            sx={{
              background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
              border: '2px solid #3F3F3F 100%',
              borderRadius: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            width={117}
            height={117}
          >
            <Image width={75} height={75} src={didNftLogo}></Image>
          </Box>
          <Button
            onClick={() => {
              hideModal()
            }}
            backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
            borderColor={'#1EB2D8'}
            width={'100%'}
            height={'42px'}
            fontSize={20}
          >
            {t('claim')}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
