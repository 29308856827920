import { useCallback, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { AppBar, Box, styled, styled as muiStyled } from '@mui/material'
import Image from 'components/Image'
import logo from '../../assets/images/dom_logo.png'
import logoWhite from '../../assets/svg/logo_white.svg'
import { routes } from 'constants/routes'
import PopperMenu from './PopperMenu'
import { HideOnMobile, ShowOnMobile } from 'theme/index'
import PopperCard from 'components/PopperCard'
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow_down.svg'
import { ExternalLink } from 'theme/components'
import mdi_twitter from 'assets/images/mdi_twitter.png'
import mdi_telegram from 'assets/images/mdi_telegram.png'
import mdi_discord from 'assets/images/mdi_discord.png'
import menuIcon from 'assets/images/menu_icon.png'
import menuClose from 'assets/images/menu_close.png'
import NewTag from 'assets/svg/new_tag.svg'
import useBreakpoint from 'hooks/useBreakpoint'
import Web3Status from './Web3Status'
import { scroller } from 'react-scroll'
import { useI18n } from 'react-simple-i18n'
import Lang from './Lang'

interface TabContent {
  title: string
  route?: string
  link?: string
  titleContent?: JSX.Element
}

interface Tab extends TabContent {
  subTab?: TabContent[]
  id: string
}
interface socialLink {
  img: string
  url: string
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  height: 63,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0',
  zIndex: theme.zIndex.drawer,
  backgroundColor: 'transparent',

  '& .link': {
    textDecoration: 'none',
    fontSize: 18,
    lineHeight: '27px',
    color: theme.palette.text.secondary,
    marginRight: 30,
    fontWeight: 400,
    // paddingBottom: '30px',
    // paddingTop: '30px',
    '&.active': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '30px'
    }
  },
  '& .menuLink': {
    textDecoration: 'none',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.text.secondary,
    marginRight: 30,
    fontWeight: 600,
    paddingBottom: '16px',
    '&.active': {
      color: theme.palette.primary.main,
      borderColor: 'rgba(0, 91, 198, 0.06)'
    },
    '&:hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '10px'
    }
  },
  [theme.breakpoints.down('lg')]: {
    '& .link': { marginRight: 20 },
    padding: '0 24px'
  },
  [theme.breakpoints.down('md')]: {
    '& .link': { marginRight: 15 },
    position: 'fixed'
  },
  [theme.breakpoints.down('sm')]: {
    '& .link': { marginRight: 0 },
    // justifyContent: 'space-around',
    height: theme.height.mobileHeader,
    padding: '0 24px',
    boxShadow: 'none'
  }
}))

const Filler = styled('div')(({ theme }) => ({
  display: 'none',
  padding: '0 20px',
  height: theme.height.mobileHeader,
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
}))

const MainLogo = styled(NavLink)(({ theme }) => ({
  '& img': {
    // width: 180.8,
    height: 63,
    display: 'block'
  },
  '&:hover': {
    cursor: 'pointer'
  },
  [theme.breakpoints.down('sm')]: {
    '& img': { width: 128, height: 'auto' },
    marginBottom: -10,
    marginRight: 5
  }
}))
const LinksWrapper = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  margin: '0 53px',
  justifyContent: 'space-around',
  a: {
    marginLeft: 44
  },
  [theme.breakpoints.down('lg')]: {
    margin: '0 30px'
  },
  [theme.breakpoints.down('md')]: {
    margin: '0 15px'
  },
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    height: 40,
    scrollbarWidth: 'none' /* firefox */,
    '-ms-overflow-style': 'none' /* IE 10+ */,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const MobileLinksWrapper = muiStyled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center'
}))

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
export const Tabs: Tab[] = [
  { title: 'home', route: routes.Home, id: 'Home' },
  { title: 'about', route: routes.Home, id: 'About' },
  {
    title: 'technology',
    id: 'Technology',
    route: routes.Home
  },
  { title: 'products', route: routes.Home, id: 'Products' }
]

export const socialLinks: socialLink[] = [
  {
    img: mdi_twitter,
    url: 'https://twitter.com/_XDIN3_'
  },
  {
    img: mdi_discord,
    url: 'https://discord.com/invite/zCWuAAbV'
  },
  {
    img: mdi_telegram,
    url: 'https://t.me/+YX0Wu1ujLJExNDVh'
  }
]

function MobileMenu() {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const { pathname } = useLocation()
  const changeMenu = (flag: boolean) => {
    console.log('changeMenu')
    setIsOpenMenu(flag)
  }
  return (
    <>
      {pathname.includes('did') ? <Web3Status isMobile={true} IsNftPage={false} /> : <Box></Box>}
      {!isOpenMenu && pathname.includes('home') ? (
        <Box onClick={() => setIsOpenMenu(true)} width={39} height={39}>
          <Image width={'100%'} height={'100%'} src={menuIcon}></Image>
        </Box>
      ) : (
        <Box></Box>
      )}
      {isOpenMenu && (
        <Box
          sx={{
            position: 'fixed',
            right: 0,
            top: 0,
            width: '100%',
            display: 'flex'
          }}
        >
          <Box onClick={() => setIsOpenMenu(false)} flex={1}></Box>
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Box
              sx={{
                height: 70,
                padding: '0 24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              <Box onClick={() => setIsOpenMenu(false)} width={39} height={39}>
                <Image width={'100%'} height={'100%'} src={menuClose}></Image>
              </Box>
            </Box>
            <Box
              sx={{
                background: '#000000',
                borderRadius: '0px 0px 30px 30px'
              }}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-center'}
              padding={'0 15px'}
            >
              <MobileTabsBox open={changeMenu} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingBottom: 40
                }}
              >
                <Web3Status IsNftPage={false} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

function TabsBox() {
  const [isNavOpen, setIsNavOpen] = useState(true)
  const { t } = useI18n()
  const clickNav = (text: string) => {
    setIsNavOpen(true)
    console.log(isNavOpen, 'isNavOpen')

    scroller.scrollTo(text, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: 0
    })
  }
  const { pathname } = useLocation()
  return (
    <LinksWrapper>
      {Tabs.map(({ title, route, subTab, link, titleContent, id }, idx) =>
        subTab ? (
          <Box
            sx={{
              color: '#3F5170',
              marginRight: {
                xs: 15,
                lg: 30
              },
              height: 'auto',
              paddingBottom: '30px',
              borderColor: theme =>
                subTab.some(tab => tab.route && pathname.includes(tab.route))
                  ? theme.palette.text.primary
                  : 'transparnet',
              display: 'flex'
            }}
            key={title + idx}
          >
            <PopperCard
              sx={{
                marginTop: 13,
                maxHeight: '50vh',
                overflowY: 'auto',
                padding: '6px 20px',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
              placement="bottom-start"
              targetElement={
                <Box
                  flexDirection={'row'}
                  display={'flex'}
                  sx={{
                    paddingTop: 30,
                    fontSize: 14,
                    color: '#fff',
                    fontWeight: 600,
                    cursor: 'pointer',
                    gap: 10,
                    '& svg:hover path': {
                      fill: '#0049C6'
                    },
                    '& svg:hover rect': {
                      stroke: '#97B7EF'
                    }
                  }}
                  alignItems={'center'}
                >
                  {title === 'AW Solutions' ? (
                    <>
                      <Box display={'flex'} alignItems={'center'} gap={4}>
                        <img src={NewTag} alt="" />
                        {title}
                      </Box>

                      <ArrowIcon />
                    </>
                  ) : (
                    <>
                      {t(title)}
                      <ArrowIcon />
                    </>
                  )}
                </Box>
              }
            >
              <>
                {subTab.map(option => (
                  <Box
                    sx={{
                      // width: 150,
                      minWidth: '150px',
                      height: 40,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#0049C60D',
                        color: '#0049C6'
                      },
                      '&:hover svg path': {
                        fill: '#0049C6'
                      },
                      '& p': {
                        marginLeft: 8
                      },
                      '&:hover p': {
                        color: '#0049C6'
                      }
                    }}
                    key={option.title}
                  >
                    {option.titleContent ?? option.title}
                  </Box>
                ))}
              </>
            </PopperCard>
          </Box>
        ) : link ? (
          <ExternalLink href={link} className={'link'} key={link + idx} style={{ fontSize: 14 }}>
            {titleContent ?? t(title)}
          </ExternalLink>
        ) : (
          <NavLink
            key={title + idx}
            id={`${route}-nav-link`}
            to={route ?? ''}
            onClick={() => clickNav(id)}
            className={
              (route
                ? pathname.includes(route)
                  ? 'active'
                  : pathname.includes('account')
                  ? route.includes('account')
                    ? 'active'
                    : ''
                  : ''
                : '') + ' link'
            }
          >
            {titleContent ?? t(title)}
          </NavLink>
        )
      )}
    </LinksWrapper>
  )
}

function MobileTabsBox({ open }: { open: any }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useI18n()
  const [isNavOpen, setIsNavOpen] = useState(true)
  const clickNav = (text: string) => {
    setIsNavOpen(true)
    open(false)
    console.log(isNavOpen, 'isNavOpen')

    scroller.scrollTo(text, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -100
    })
  }
  return (
    <MobileLinksWrapper>
      {Tabs.map(({ title, route, subTab, link, titleContent, id }, idx) =>
        subTab ? (
          <Box
            sx={{
              color: '#3F5170',
              marginRight: {
                xs: 15,
                lg: 30
              },
              height: 'auto',
              paddingBottom: '30px',
              borderColor: theme =>
                subTab.some(tab => tab.route && pathname.includes(tab.route))
                  ? theme.palette.text.primary
                  : 'transparnet',
              display: 'flex'
            }}
            key={title + idx}
          >
            <PopperCard
              sx={{
                marginTop: 13,
                maxHeight: '50vh',
                overflowY: 'auto',
                padding: '6px 20px',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
              placement="bottom-start"
              targetElement={
                <Box
                  flexDirection={'row'}
                  display={'flex'}
                  sx={{
                    paddingTop: 30,
                    fontSize: 18,
                    color: '#fff',
                    fontWeight: 600,
                    cursor: 'pointer',
                    gap: 10,
                    '& svg:hover path': {
                      fill: '#0049C6'
                    },
                    '& svg:hover rect': {
                      stroke: '#97B7EF'
                    }
                  }}
                  alignItems={'center'}
                >
                  {
                    <>
                      {t(title)}
                      <ArrowIcon />
                    </>
                  }
                </Box>
              }
            >
              <>
                {subTab.map(option => (
                  <Box
                    sx={{
                      // width: 150,
                      minWidth: '150px',
                      height: 40,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: '#0049C60D',
                        color: '#0049C6'
                      },
                      '&:hover svg path': {
                        fill: '#0049C6'
                      },
                      '& p': {
                        marginLeft: 8
                      },
                      '&:hover p': {
                        color: '#0049C6'
                      }
                    }}
                    key={option.title}
                    onClick={() => {
                      option.route ? navigate(option.route) : window.open(option.link, '_blank')
                    }}
                  >
                    {option.titleContent ?? t(option.title)}
                  </Box>
                ))}
              </>
            </PopperCard>
          </Box>
        ) : link ? (
          <ExternalLink href={link} className={'link'} key={link + idx} style={{ fontSize: 14 }}>
            {titleContent ?? t(title)}
          </ExternalLink>
        ) : (
          <NavLink
            key={title + idx}
            id={`${route}-nav-link`}
            to={route ?? ''}
            onClick={() => clickNav(id)}
            className={
              (route
                ? pathname.includes(route)
                  ? 'active'
                  : pathname.includes('account')
                  ? route.includes('account')
                    ? 'active'
                    : ''
                  : ''
                : '') + ' link'
            }
          >
            {titleContent ?? t(title)}
          </NavLink>
        )
      )}
    </MobileLinksWrapper>
  )
}

export default function Header() {
  const { pathname } = useLocation()
  const isMdDown = useBreakpoint('md')
  const daoId = useMemo(() => {
    const path = pathname.split('/')
    return path[3]
  }, [pathname])

  const makeRouteLink = useCallback((route: string) => route.replace(':daoId', daoId), [daoId])
  const IsNftPage = useMemo(() => {
    if (pathname.includes(makeRouteLink(routes._NftAccount))) {
      return true
    }
    return false
  }, [pathname, makeRouteLink])

  const IsDidPage = useMemo(() => {
    if (pathname.includes(makeRouteLink('did'))) {
      return true
    }

    return false
  }, [pathname, makeRouteLink])
  return (
    <>
      <Filler />
      <StyledAppBar
        sx={{
          top: 0,
          boxShadow: 'none',
          background: isMdDown ? '#000' : 'transparnet',
          margin: isMdDown ? 0 : '65px auto 0',
          paddingRight: '0!important',
          width: isMdDown ? '100%' : { xl: 1440, lg: 1292 }
        }}
      >
        <Box
          paddingRight={isMdDown ? 24 : 0}
          width={'100%'}
          display="flex"
          justifyContent={isMdDown ? 'space-between' : ''}
          alignItems={'center'}
        >
          <MainLogo to={routes.Home}>
            <Image src={IsNftPage ? logoWhite : logo} alt={'logo'} />
          </MainLogo>

          <HideOnMobile
            style={{
              flex: 1
            }}
            breakpoint="md"
          >
            {!IsDidPage && <TabsBox />}
          </HideOnMobile>
          <HideOnMobile>
            <Lang />
          </HideOnMobile>
          <HideOnMobile breakpoint="md">
            <Web3Status IsNftPage={false} />
          </HideOnMobile>
          <ShowOnMobile breakpoint="md">
            <Box display={'flex'} alignItems={'center'}>
              <Lang />
              <MobileMenu />
            </Box>
          </ShowOnMobile>
        </Box>
      </StyledAppBar>
    </>
  )
}

export function HeaderRight({ IsNftPage }: { IsNftPage: boolean }) {
  console.log(IsNftPage)

  return (
    <Box
      display={{ sm: 'flex', xs: 'flex' }}
      gridTemplateColumns={{ sm: 'unset', xs: 'auto auto auto auto' }}
      alignItems="flex-end"
      gap={{ xs: '10px', sm: '10px' }}
    >
      <PopperMenu />
    </Box>
  )
}
