import { Box, Typography } from '@mui/material'
// import { ReactComponent as ShowDropDown } from 'assets/svg/moreMenuIcon.svg'
import Image from 'components/Image'

import arrow from '../../assets/images/squad_arrow.png'
import SquadAvatar from '../../assets/images/squad_avatar.png'
import { useActiveWeb3React } from 'hooks'
// import { ReactComponent as BugIcon } from 'assets/svg/bugIcon.svg'
import PopperCard from 'components/PopperCard'
import { routes } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { useGetUserDetail } from 'hooks/useBackedUserServe'
// import { useEffect } from 'react'
const menuList = [
  {
    title: 'My Event',
    route: routes.MyEvent
  },
  {
    title: 'Edit Profile',
    route: routes.EditProfile
  },
  {
    title: 'Sign Out',
    route: ''
  }
]

export default function PopperMenu() {
  const { deactivate } = useActiveWeb3React()
  const { result } = useGetUserDetail()
  // const [userInfo, setUserInfo] = useState<SquadUserProp>()

  // useEffect(() => {
  //   console.log(headPhoto, name, 'PopperMenu updateNumber')
  // }, [headPhoto, name])

  const navigate = useNavigate()

  const selectMenu = (option: any) => {
    if (option.title === 'Sign Out') {
      deactivate()
      return
    }
    navigate(option.route)
  }
  return (
    <Box>
      <PopperCard
        sx={{
          marginTop: 13,
          maxHeight: '50vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
        placement="bottom-start"
        targetElement={
          <Box
            flexDirection={'row'}
            display={'flex'}
            sx={{
              cursor: 'pointer',
              '& svg': {
                width: 36,
                height: 36
              },
              '& svg:hover path': {
                fill: '#0049C6'
              },
              '& svg:hover rect': {
                stroke: '#97B7EF'
              }
            }}
            alignItems={'center'}
          >
            {result && result?.headPhoto ? (
              <Image width={34} height={34} src={result.headPhoto || ''} alt={'arrow'} />
            ) : (
              <Image width={34} height={34} src={SquadAvatar} alt={'arrow'} />
            )}

            <Box
              sx={{
                color: '#000000',
                fontSize: 14,
                margin: '0 8px'
              }}
            >
              {result && result?.name ? result.name : 'Unnamed'}
            </Box>

            {/* <ShowDropDown /> */}
            <Image src={arrow} alt={'arrow'} />
          </Box>
        }
      >
        <Box
          sx={{
            padding: '14px 11px 1px 11px'
          }}
        >
          {menuList.map(option => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                marginBottom: 10,
                '&:hover': {
                  backgroundColor: '#0049C60D',
                  colorr: '#0049C6'
                },
                '&:hover svg path': {
                  fill: '#0049C6'
                },
                '&:hover p': {
                  color: '#0049C6'
                }
              }}
              key={option.title}
              onClick={() => selectMenu(option)}
            >
              <Typography fontWeight={600} lineHeight={'20px'} fontSize={16}>
                {option.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </PopperCard>
    </Box>
  )
}
