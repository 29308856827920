import { Box, Stack } from '@mui/material'
import useBreakpoint from 'hooks/useBreakpoint'

import aboutLogo1 from 'assets/images/about_logo_1.png'
import aboutLogo2 from 'assets/images/about_logo_2.png'
import aboutDomLogo from 'assets/images/about_dom_logo.png'
import mobileDomImg from 'assets/images/mobile_dom_img.png'
import mobileDomImgKR from 'assets/images/mobile_dom_img_kr.png'
import mobileDomImgJP from 'assets/images/mobile_dom_img_jp.png'
import domGroup1 from 'assets/images/dom_group_1.png'
import domGroup2 from 'assets/images/dom_group_2.png'
import domGroup3 from 'assets/images/dom_group_3.png'
import domGroup4 from 'assets/images/dom_group_4.png'
import domGroup5 from 'assets/images/dom_group_5.png'
import domGroup6 from 'assets/images/dom_group_6.png'
import Image from 'components/Image'
import { useI18n } from 'react-simple-i18n'

export default function About() {
  const isSmDown = useBreakpoint('sm')
  const { t, i18n } = useI18n()
  return (
    <Box
      id={'About'}
      width={isSmDown ? 'auto' : { xl: 1440, lg: 1292 }}
      position={'relative'}
      margin={isSmDown ? '0 24px' : '0 auto'}
      mt={isSmDown ? 62 : 105}
    >
      <Stack direction={isSmDown ? 'column' : 'row'} spacing={20}>
        <Box
          sx={{
            flex: 1,
            textAlign: 'left'
          }}
        >
          <Box
            sx={{
              fontSize: isSmDown ? 52 : 72,
              fontWeight: 300,
              lineHeight: isSmDown ? '58px' : '108px',
              background: 'linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('about')}
          </Box>
          <Box
            sx={{
              fontSize: isSmDown ? 14 : 18,
              lineHeight: isSmDown ? '21px' : '27px',
              fontWeight: 400,
              marginBottom: 10,
              marginTop: 10,
              color: '#fff'
            }}
          >
            {t('aboutPage.content1')}
          </Box>
          <Box
            sx={{
              color: '#a2a2a2',
              fontSize: isSmDown ? 12 : 14,
              lineHeight: isSmDown ? '18px' : '21px'
            }}
          >
            {t('aboutPage.content2')}
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
            border: '1px solid #242424',
            padding: '30px 10px'
          }}
        >
          <Image width={105} height={104} src={aboutLogo1}></Image>
          <Box
            mt={20}
            mb={10}
            sx={{
              fontSize: isSmDown ? 14 : 18,
              fontWeight: 600,
              lineHeight: isSmDown ? '21px' : '27px',
              color: '#fff'
            }}
          >
            {t('aboutPage.title1')}
          </Box>
          <Box
            sx={{
              fontSize: isSmDown ? 12 : 14,
              fontWeight: 400,
              lineHeight: isSmDown ? '18px' : '21px',
              color: '#a2a2a2'
            }}
          >
            {t('aboutPage.desc1')}
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            borderRadius: '20px',
            background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
            border: '1px solid #242424',
            padding: '30px 10px'
          }}
        >
          <Image width={105} height={104} src={aboutLogo2}></Image>
          <Box
            mt={20}
            mb={10}
            sx={{
              fontSize: isSmDown ? 14 : 18,
              fontWeight: 600,
              lineHeight: isSmDown ? '21px' : '27px',
              color: '#fff'
            }}
          >
            {t('aboutPage.title2')}
          </Box>
          <Box
            sx={{
              fontSize: isSmDown ? 12 : 14,
              fontWeight: 400,
              lineHeight: isSmDown ? '18px' : '21px',
              color: '#a2a2a2'
            }}
          >
            {t('aboutPage.desc2')}
          </Box>
        </Box>
      </Stack>
      {isSmDown ? (
        <Box
          id={'Technology'}
          mt={133}
          mb={66}
          sx={{
            fontSize: 40,
            fontWeight: 300,
            lineHeight: '48px',
            textAlign: 'left',
            background: 'linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }}
        >
          <Box>{t('technologyPage.title')}</Box>
        </Box>
      ) : (
        <Box
          id={'Technology'}
          mt={133}
          mb={66}
          sx={{
            fontSize: 72,
            fontWeight: 300,
            lineHeight: '108px',
            background: 'linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }}
        >
          <Box>{t('technologyPage.title')}</Box>
        </Box>
      )}
      {isSmDown ? (
        <Box width={'100%'}>
          <Image
            width={'100%'}
            src={i18n.getLang() === 'JP' ? mobileDomImgJP : i18n.getLang() === 'kr' ? mobileDomImgKR : mobileDomImg}
          ></Image>
        </Box>
      ) : (
        <Stack direction={'row'}>
          <Box
            width={320}
            sx={{
              color: '#fff',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '27px',
              textAlign: 'left'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
              height={160}
            >
              {t('technologyPage.text1')}
            </Box>
            <Box
              sx={{
                height: '1px',
                widht: '100%',
                backgroundColor: '#32d0d6'
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
              height={160}
            >
              {t('technologyPage.text2')}
            </Box>
            <Box
              sx={{
                height: '1px',
                widht: '100%',
                backgroundColor: '#32d0d6'
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
              height={160}
            >
              {t('technologyPage.text3')}
            </Box>
          </Box>
          <Box
            sx={{
              flex: '1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {/* left image box */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: 86
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(50%, -50%)'
                  }}
                  width={86}
                  height={86}
                >
                  <Image width={'100%'} height={'100%'} src={domGroup1}></Image>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '20px',
                    transform: 'translateY(-50%)'
                  }}
                  width={86}
                  height={86}
                >
                  <Image width={'100%'} height={'100%'} src={domGroup2}></Image>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(50%, -50%)'
                  }}
                  width={86}
                  height={86}
                >
                  <Image width={'100%'} height={'100%'} src={domGroup3}></Image>
                </Box>
              </Box>
            </Box>
            <Image width={325} height={325} src={aboutDomLogo}></Image>
            {/* right image box */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: 86
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                  width={86}
                  height={86}
                >
                  <Image width={'100%'} height={'100%'} src={domGroup4}></Image>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '20px',
                    transform: 'translateY(-50%)'
                  }}
                  width={86}
                  height={86}
                >
                  <Image width={'100%'} height={'100%'} src={domGroup5}></Image>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  position: 'relative'
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                  width={86}
                  height={86}
                >
                  <Image width={'100%'} height={'100%'} src={domGroup6}></Image>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width={320}
            sx={{
              color: '#D3D6DF',
              fontSize: 18,
              fontWeight: 400,
              lineHeight: '27px',
              textAlign: 'right'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
              height={160}
            >
              {t('technologyPage.text4')}
            </Box>
            <Box
              sx={{
                height: '1px',
                widht: '100%',
                backgroundColor: '#32d0d6'
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
              height={160}
            >
              {t('technologyPage.text5')}
            </Box>
            <Box
              sx={{
                height: '1px',
                widht: '100%',
                backgroundColor: '#32d0d6'
              }}
            ></Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
              height={160}
            >
              {t('technologyPage.text6')}
            </Box>
          </Box>
        </Stack>
      )}
    </Box>
  )
}
