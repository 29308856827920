import { Suspense, useEffect } from 'react'
import BigNumber from 'bignumber.js'
BigNumber.config({ EXPONENTIAL_AT: [-7, 40] })
import { Navigate, Route, Routes } from 'react-router-dom'
import { styled, useTheme } from '@mui/material'
import Header from '../components/Header'
import Polling from '../components/essential/Polling'
import Popups from '../components/essential/Popups'
import { ModalProvider } from 'context/ModalContext'
import { routes } from 'constants/routes'
import Home from 'pages/Home'
import MDid from 'pages/DID/MDid'
import Did from 'pages/DID/Did'
import { isMobile } from 'react-device-detect'
import GoogleAnalyticsReporter from 'components/analytics/GoogleAnalyticsReporter'
import { fetchUserLocation } from 'utils/fetch/location'
import store from 'state'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createI18n, I18nProvider } from 'react-simple-i18n'
import { langData } from '../langs/lang'

const AppWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  overflowX: 'hidden',
  background: theme.palette.background.paper,
  [`& .border-tab-item`]: {
    position: 'relative',
    '&.active': {
      '&:after': {
        content: `''`,
        position: 'absolute',
        bottom: 0,
        right: 0,
        left: 0,
        height: 4,
        backgroundColor: theme.palette.text.primary,
        borderRadius: '2px 2px 0px 0px'
      }
    }
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
    // height: '100vh'
  }
}))

const ContentWrapper = styled('div')({
  width: '100%',
  overflow: 'auto',
  alignItems: 'center',
  '& .toast-container': {
    width: 446,
    zIndex: 199999,
    '& .Toastify__toast': {
      borderRadius: '10px',
      border: '1px solid #97B7EF'
    },
    '& .Toastify__toast-body': {
      justifyContent: 'center',
      color: '#3F5170',
      fontWeight: 500,
      fontSize: 14
    }
  }
})

const BodyWrapper = styled('div')(({}) => ({
  // minHeight: '100vh'
}))

export default function App() {
  const theme = useTheme()
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') return
    fetchUserLocation().then(res => {
      store.dispatch({
        type: 'application/setUserLocation',
        payload: res.data || null
      })
    })
  }, [])

  return (
    <Suspense fallback={null}>
      <I18nProvider
        i18n={createI18n(langData, {
          lang: 'EN'
        })}
      >
        <ModalProvider>
          <AppWrapper id="app">
            <GoogleAnalyticsReporter />
            <ContentWrapper>
              <Header />
              <ToastContainer
                className={'toast-container'}
                hideProgressBar
                position="top-center"
                pauseOnFocusLoss={false}
              />
              <BodyWrapper
                id="body"
                sx={{
                  minHeight: 'calc(100vh - 80px)',
                  marginTop: '-128px',
                  [theme.breakpoints.down('md')]: {
                    minHeight: `${theme.height.mobileHeader} - 50px`,
                    marginTop: '-128px'
                  }
                }}
              >
                <Popups />
                <Polling />
                <Routes>
                  <Route path={routes.Home} element={<Home />} />
                  <Route path="/did" element={isMobile ? <MDid /> : <Did />} />
                  <Route path="*" element={<Navigate replace to={routes.Home} />} />
                </Routes>
              </BodyWrapper>
            </ContentWrapper>
          </AppWrapper>
        </ModalProvider>
      </I18nProvider>
    </Suspense>
  )
}
