import { Box, Stack } from '@mui/material'
import useBreakpoint from 'hooks/useBreakpoint'
import Image from 'components/Image'
import logo1 from 'assets/images/Ecosystem_logo_1.png'
import logo2 from 'assets/images/Ecosystem_logo_2.png'
import logo3 from 'assets/images/Ecosystem_logo_3.png'
import logo4 from 'assets/images/Ecosystem_logo_4.png'
import logo5 from 'assets/images/Ecosystem_logo_5.png'
import logo6 from 'assets/images/Ecosystem_logo_6.png'
import logo7 from 'assets/images/Ecosystem_logo_7.png'
import logo8 from 'assets/images/Ecosystem_logo_8.png'
import logo9 from 'assets/images/Ecosystem_logo_9.png'
import logo10 from 'assets/images/Ecosystem_logo_10.png'
import logo11 from 'assets/images/Ecosystem_logo_11.png'
import logo12 from 'assets/images/Ecosystem_logo_12.png'
import { useI18n } from 'react-simple-i18n'
// import youtube from 'assets/images/youtube.png'
import gitbook from 'assets/images/gitbook.png'
import medium from 'assets/images/mingcute.png'
import x from 'assets/images/x.png'
import telegram from 'assets/images/telegram.png'

interface EcosystemProp {
  icon: string
}

interface socialLink {
  img: string
  url: string
}
const EcosystemList = [
  {
    icon: logo1
  },
  {
    icon: logo2
  },
  {
    icon: logo3
  },
  {
    icon: logo4
  }
]

const MEcosystemList = [
  {
    icon: logo1
  },
  {
    icon: logo2
  },
  {
    icon: logo3
  },
  {
    icon: logo4
  },
  {
    icon: logo5
  },
  {
    icon: logo6
  },
  {
    icon: logo7
  },
  {
    icon: logo8
  },
  {
    icon: logo9
  },
  {
    icon: logo10
  },
  {
    icon: logo11
  },
  {
    icon: logo12
  }
]

const EcosystemList2 = [
  {
    icon: logo5
  },
  {
    icon: logo6
  },
  {
    icon: logo7
  },
  {
    icon: logo8
  }
]

const EcosystemList3 = [
  {
    icon: logo9
  },
  {
    icon: logo10
  },
  {
    icon: logo11
  },
  {
    icon: logo12
  }
]

const socialLinks: socialLink[] = [
  // {
  //   img: youtube,
  //   url: 'https://twitter.com'
  // },
  {
    img: gitbook,
    url: 'https://domusai-organization.gitbook.io/domusai-documentation'
  },
  {
    img: medium,
    url: 'https://medium.com/@DomusAI'
  },
  {
    img: x,
    url: 'https://twitter.com/DomusAI_com'
  },
  {
    img: telegram,
    url: 'https://t.me/DomusAI_group'
  }
]

export default function Ecosystem() {
  const isSmDown = useBreakpoint('sm')
  const { t } = useI18n()
  return (
    <Box
      position={'relative'}
      margin={isSmDown ? '0 24px' : '0 auto'}
      width={isSmDown ? 'auto' : { xl: 1440, lg: 1292 }}
      mt={isSmDown ? 0 : 69}
    >
      <Box
        sx={{
          borderRadius: '20px',
          backgroundColor: 'transparent',
          border: '1px solid #52FFD2'
        }}
      >
        <Box
          sx={{
            padding: isSmDown ? '50px 15px 0 15px' : 50
          }}
        >
          <Box
            mb={50}
            sx={{
              fontSize: 32,
              lineHeight: '48px',
              fontWeight: 500,
              background: ' linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('ecosystem')}
          </Box>
          {isSmDown && (
            <Stack
              justifyContent={'space-between'}
              alignItems={'center'}
              direction={'row'}
              sx={{
                flexWrap: 'wrap'
              }}
            >
              {MEcosystemList.map((item: EcosystemProp) => {
                return (
                  <Box mb={30} width={'45%'} key={item.icon}>
                    <Image width={'100%'} src={item.icon}></Image>
                  </Box>
                )
              })}
            </Stack>
          )}
          {!isSmDown && (
            <Stack mb={35} justifyContent={'space-evenly'} alignItems={'center'} direction={'row'}>
              {EcosystemList.map((item: EcosystemProp) => {
                return (
                  <Box
                    sx={{
                      borderRadius: '15px',
                      background: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    width={280}
                    height={100}
                    key={item.icon}
                  >
                    <Image src={item.icon}></Image>
                  </Box>
                )
              })}
            </Stack>
          )}
          {!isSmDown && (
            <Stack mb={35} justifyContent={'space-evenly'} alignItems={'center'} direction={'row'}>
              {EcosystemList2.map((item: EcosystemProp) => {
                return (
                  <Box
                    sx={{
                      borderRadius: '15px',
                      background: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    width={280}
                    height={100}
                    key={item.icon}
                  >
                    <Image src={item.icon}></Image>
                  </Box>
                )
              })}
            </Stack>
          )}
          {!isSmDown && (
            <Stack justifyContent={'space-evenly'} alignItems={'center'} direction={'row'}>
              {EcosystemList3.map((item: EcosystemProp) => {
                return (
                  <Box
                    sx={{
                      borderRadius: '15px',
                      background: '#fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    width={280}
                    height={100}
                    key={item.icon}
                  >
                    <Image src={item.icon}></Image>
                  </Box>
                )
              })}
            </Stack>
          )}
        </Box>
      </Box>
      <Stack
        mt={isSmDown ? 50 : 130}
        direction={isSmDown ? 'column' : 'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Box
          sx={{
            color: '#90BACC',
            textAlign: isSmDown ? 'center' : 'left'
          }}
        >
          <Box
            sx={{
              fontWeight: 700
            }}
          >
            DomusAI
          </Box>
          <Box
            sx={{
              whiteSpace: 'nowrap'
            }}
          >
            {t('foot')}
          </Box>
        </Box>

        <Box width={'100%'}>
          <Stack
            width={'100%'}
            sx={{
              cursor: 'pointer'
            }}
            spacing={isSmDown ? 0 : 55}
            direction={'row'}
            justifyContent={isSmDown ? 'space-between' : 'flex-end'}
            mt={isSmDown ? 20 : 0}
          >
            {socialLinks.map((item: socialLink) => {
              return (
                <Image
                  onClick={() => window.open(item.url)}
                  width={39}
                  height={39}
                  key={item.url}
                  src={item.img}
                ></Image>
              )
            })}
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
