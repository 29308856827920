import { Box, Stack } from '@mui/material'
// import bgBlock from 'assets/images/bg-image.png'
// import dinosaur1 from 'assets/images/dinosaur1.png'
import IsolationMode from 'assets/images/Isolation_mode.png'
import Button from 'components/Button/Button'
import { useCallback, useState, useEffect } from 'react'
import { useActiveWeb3React } from '../../hooks'
import Image from 'components/Image'
import sIcon1 from 'assets/images/s_icon_1.png'
import sIcon2 from 'assets/images/s_icon_2.png'
import sIcon3 from 'assets/images/s_icon_3.png'
import sIcon4 from 'assets/images/s_icon_4.png'
import sIcon5 from 'assets/images/s_icon_5.png'
import sIcon6 from 'assets/images/s_icon_6.png'
import sIcon7 from 'assets/images/s_icon_7.png'
import { useDomDid } from '../../hooks/useDomDid'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import useModal from '../../hooks/useModal'
import { useWalletModalToggle } from 'state/application/hooks'
import SucceedModal from 'components/Modal/Succeed/index'
import { useI18n } from 'react-simple-i18n'
import mDidBg from 'assets/images/m_did_bg.png'
import { Tabs } from 'components/Tabs/Tabs'
import didNftLogo from 'assets/images/did_nft_logo_v2.png'
export interface visionProp {
  icon: string
  title: string
  desc: string
}

interface ArticleProps {
  title: string
  content: string
}

interface IconArticleList extends ArticleProps {
  icon: string
}

const SectionArticle = (props: ArticleProps) => {
  const { title, content } = props

  return (
    <Box>
      <Box
        fontSize={13}
        lineHeight={'19.5px'}
        fontWeight={700}
        mb={3}
        sx={{
          background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent'
        }}
      >
        {title}
      </Box>
      <Box fontSize={10} lineHeight={'15PX'} fontWeight={400} color={'#fff'}>
        {content}
      </Box>
    </Box>
  )
}

const SectionIconArticle = (props: IconArticleList) => {
  const { title, content, icon } = props

  return (
    <Box
      padding={'20px 10px'}
      borderRadius={'20px'}
      sx={{
        background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)'
      }}
    >
      <Box>
        <Image src={icon}></Image>
      </Box>
      <SectionArticle title={title} content={content} />
    </Box>
  )
}

const DIDIntroduction = () => {
  const { t } = useI18n()
  const DIDIntroductionArticleList = [
    {
      title: t('pageDID.introduction.list.title1'),
      content: t('pageDID.introduction.list.desc1')
    },
    {
      title: t('pageDID.introduction.list.title2'),
      content: t('pageDID.introduction.list.desc2')
    },
    {
      title: t('pageDID.introduction.list.title3'),
      content: t('pageDID.introduction.list.desc3')
    },
    {
      title: t('pageDID.introduction.list.title4'),
      content: t('pageDID.introduction.list.desc4')
    }
  ]
  return (
    <Box padding={'18px 0'}>
      <Box
        sx={{
          fontSize: 12,
          fontWeight: 400,
          lineHeight: '18px',
          color: '#fff'
        }}
      >
        {t('pageDID.introduction.content')}
      </Box>
      <Box margin={'24px auto'} width={210}>
        <Image width={'100%'} src={IsolationMode}></Image>
      </Box>
      <Box
        sx={{
          borderRadius: '20px',
          padding: '20px 10px',
          background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)'
        }}
      >
        <Box
          fontSize={17}
          lineHeight={'25.5px'}
          fontWeight={600}
          sx={{
            background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }}
        >
          {t('pageDID.introduction.subtitle')}
        </Box>
        {DIDIntroductionArticleList.map((item, idx) => {
          return (
            <Box mb={8} key={idx}>
              <SectionArticle title={item.title} content={item.content} />
            </Box>
          )
        })}
      </Box>
      <Box mt={24} fontSize={12} lineHeight={'18px'} fontWeight={400} color={'#fff'}>
        {t('pageDID.introduction.content2')}
      </Box>
    </Box>
  )
}

const DidNft = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
        border: '2px solid #3F3F3F 100%',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      width={117}
      height={117}
    >
      <Image width={75} height={75} src={didNftLogo}></Image>
    </Box>
  )
}

const MyContribution = (mintedCount: any) => {
  console.log(mintedCount, 'mintedCount')
  if (mintedCount && mintedCount.length > 0) {
    return (
      <Box
        pb={18}
        sx={{
          display: 'grid',
          'grid-gap': '38px',
          'grid-template-columns': '117px 117px',
          'grid-template-rows': '137px',
          'justify-content': 'center'
        }}
        mt={18}
      >
        {mintedCount &&
          mintedCount.map((item: any) => {
            return (
              <Box width={117} key={item}>
                <DidNft />
                <Box
                  sx={{
                    fontSize: 13,
                    lineHeight: '19.5px',
                    color: '#fff'
                  }}
                >
                  I own : {item?.toString()}
                </Box>
              </Box>
            )
          })}
      </Box>
    )
  }
  return <></>
}

const DIDBenefits = () => {
  const { t } = useI18n()
  const DIDBenefitsArticleList = [
    {
      title: t('pageDID.benefits.list.title1'),
      icon: sIcon1,
      content: t('pageDID.benefits.list.desc1')
    },
    {
      title: t('pageDID.benefits.list.title2'),
      icon: sIcon2,
      content: t('pageDID.benefits.list.desc2')
    },
    {
      title: t('pageDID.benefits.list.title3'),
      icon: sIcon3,
      content: t('pageDID.benefits.list.desc3')
    },
    {
      title: t('pageDID.benefits.list.title4'),
      icon: sIcon4,
      content: t('pageDID.benefits.list.desc4')
    },
    {
      title: t('pageDID.benefits.list.title5'),
      icon: sIcon5,
      content: t('pageDID.benefits.list.desc5')
    },
    {
      title: t('pageDID.benefits.list.title6'),
      icon: sIcon6,
      content: t('pageDID.benefits.list.desc6')
    },
    {
      title: t('pageDID.benefits.list.title7'),
      icon: sIcon7,
      content: t('pageDID.benefits.list.desc7')
    }
  ]
  return (
    <Box padding={'18px 0'}>
      <Box mb={24} fontSize={12} lineHeight={'18px'} fontWeight={400} color={'#fff'}>
        {t('pageDID.benefits.subtitle')}
      </Box>
      {DIDBenefitsArticleList.map((item, idx) => {
        return (
          <Box mb={10} key={idx}>
            <SectionIconArticle title={item.title} icon={item.icon} content={item.content} />
          </Box>
        )
      })}
      <Box mt={24} fontSize={12} lineHeight={'18px'} fontWeight={400} color={'#fff'}>
        {t('pageDID.benefits.content')}
      </Box>
    </Box>
  )
}

export default function MDid() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t } = useI18n()
  const { mint, mintedCount } = useDomDid()
  const { showModal, hideModal } = useModal()
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const [count, setCount] = useState(1)
  const mintCallback = useCallback(async () => {
    if (!account || !count) return
    showModal(<TransactionPendingModal />)
    mint(count)
      .then(() => {
        hideModal()
        showModal(<SucceedModal num={count} />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, count, showModal, mint, hideModal])

  function NFTButtomFun() {
    if (!account) {
      return (
        <>
          <Button
            onClick={toggleWalletModal}
            backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
            borderColor={'#1EB2D8'}
            width={'100%'}
            height={'42px'}
            fontSize={20}
          >
            {t('connect')}
          </Button>
        </>
      )
    }
    return (
      <>
        <Button
          onClick={mintCallback}
          backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
          borderColor={'#1EB2D8'}
          width={'100%'}
          height={'42px'}
          fontSize={20}
        >
          {t('pageDID.mint')}
        </Button>
      </>
    )
  }
  return (
    <Box
      sx={{
        background: '#000',
        position: 'relative',
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        fontSize: 12,
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'relative'
        }}
        mt={128}
        width={'100%'}
      >
        <Image width={'100%'} src={mDidBg}></Image>
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)'
          }}
        >
          <Box
            sx={{
              fontSize: 16,
              lineHeight: '24px',
              fontWeight: 500,
              background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('pageDID.welcome')}
          </Box>
          <Box
            sx={{
              fontSize: 64,
              lineHeight: '96px',
              fontWeight: 600,
              background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            DomusAI
          </Box>
          <Box
            sx={{
              fontSize: 14,
              lineHeight: '21px',
              fontWeight: 600,
              color: '#fff'
            }}
          >
            {t('pageDID.content1')}
          </Box>
          <Box
            sx={{
              fontSize: 14,
              lineHeight: '21px',
              fontWeight: 600,
              color: '#fff'
            }}
          >
            {t('pageDID.content2')}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
          padding: '20px 0',
          'border-top': '1px solid',
          'border-bottom': '1px solid',
          'border-image': 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%) 1'
        }}
      >
        <Box
          sx={{
            color: '#fff',
            fontSize: 14,
            lineHeight: '21px',
            fontWeight: 600,
            margin: '0 auto'
          }}
          width={270}
        >
          {t('pageDID.content3')}
        </Box>
        <Box
          mt={17}
          mb={10}
          sx={{
            fontSize: 52,
            lineHeight: '78px',
            fontWeight: 600,
            background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }}
        >
          {t('pageDID.content4')}
        </Box>
        <Box
          sx={{
            fontSize: 16,
            lineHeight: '24px',
            fontWeight: 600,
            background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }}
        >
          {t('pageDID.content5')}
        </Box>
      </Box>
      <Box padding={20}>
        <Box
          sx={{
            padding: '20px',
            background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
            borderRadius: '20px',
            textAlign: 'left'
          }}
        >
          <Box
            sx={{
              fontSize: 18,
              lineHeight: '27px',
              fontWeight: 400,
              background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('pageDID.freeMint')}
          </Box>
          <Box
            mt={5}
            mb={40}
            sx={{
              fontSize: 32,
              lineHeight: '48px',
              fontWeight: 600,
              background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('pageDID.text1')}
          </Box>
          <Box fontSize={12} lineHeight={'18px'} fontWeight={400} color={'#fff'}>
            {t('pageDID.FrennMint')}
          </Box>
          <Box mt={4} mb={20} fontSize={18} lineHeight={'27px'} fontWeight={600} color={'#fff'}>
            DomusAI DID
          </Box>
          <Box fontSize={12} lineHeight={'18px'} fontWeight={400} color={'#fff'}>
            {t('pageDID.price')}
          </Box>
          <Box mt={4} mb={20} fontSize={18} lineHeight={'27px'} fontWeight={600} color={'#fff'}>
            {t('pageDID.free')}
          </Box>
          <Box fontSize={12} lineHeight={'18px'} fontWeight={400} color={'#fff'}>
            {t('pageDID.amount')}
          </Box>
          <Box mt={10}>
            <Stack direction={'row'}>
              <Button
                disabled={count == 1}
                onClick={() => setCount(count - 1)}
                style={{
                  height: 34,
                  lineHeight: '30px',
                  background: '#000',
                  fontWeight: 600,
                  fontSize: 15,
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  border: '2px solid #FFFFFF',
                  color: '#fff',
                  flex: 1
                }}
              >
                -
              </Button>
              <Box
                sx={{
                  flex: 1,
                  textAlign: 'center',
                  lineHeight: '34px',
                  fontWeight: 600,
                  fontSize: 18,
                  width: '95px',
                  height: 34,
                  borderRadius: '20px',
                  color: '#fff',
                  background: '#272727',
                  margin: '0 14px'
                }}
              >
                {count}
              </Box>
              <Button
                onClick={() => setCount(count + 1)}
                style={{
                  height: 34,
                  lineHeight: '30px',
                  fontWeight: 600,
                  background: '#000',
                  fontSize: 15,
                  textAlign: 'center',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  border: '2px solid #FFFFFF',
                  color: '#fff',
                  flex: 1
                }}
              >
                +
              </Button>
            </Stack>
          </Box>
          <Box mt={40}>
            <NFTButtomFun />
          </Box>
        </Box>
      </Box>
      <Box>
        <Tabs
          titles={[t('pageDID.introduction.title'), t('pageDID.contribution.title'), t('pageDID.benefits.title')]}
          contents={[DIDIntroduction(), MyContribution(mintedCount), DIDBenefits()]}
        />
      </Box>
      <Box pb={20} mt={20} color={'#fff'} fontSize={10} lineHeight={'25px'} textAlign={'center'}>
        © Meta Ventures International LLC . All Rights Reserved 2024.
      </Box>
    </Box>
  )
}
