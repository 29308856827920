export const KR = {
  home: '홈페이지',
  about: '회사 소개',
  technology: '기술',
  products: '제품',
  SignUp: '가입하기',
  freeMint: '무료 민트',
  connect: '가입하기',
  homePage: {
    content1: '종합적인 Web3 스마트 홈 시스템',
    content2:
      'DomusAI는 AI 기반의 글로벌 구현으로 새로운 경험을 제공합니다. 우리는 Web3 세계에서 구축된 시나리오를 전 세계 수천 가구로 가져옵니다.'
  },
  DIDowners: 'DID 소유자',
  ActiveUsers: '활성 사용자',
  SmarthomeInstalled: '설치된 스마트홈',
  aboutPage: {
    content1: '스타트업에서 산업 리더로 - DomusAl은 스마트 홈 산업에서 혁신의 선두에 서 있습니다.',
    content2:
      '기술 혁신에 헌신하여 전 세계의 안전과 삶의 질을 향상시키고 있습니다. 최고의 엔지니어, 디자이너 및 사고 리더로 구성된 저희 팀은 저희의 성공의 기반이 되었습니다.',
    title1: '주요 기능',
    desc1:
      '보안과 지능이 결합 - DomusAl은 Web3 기술을 통합하여 데이터 유출로부터 보호합니다. 또한, 저희의 AI 시스템은 사용자의 생활 습관을 학습하여 집 기기를 자동으로 조정하여 맞춤형 요구에 맞게 제공합니다.',
    title2: '기술로 미래를 주도하다',
    desc2:
      'DomusAl은 인공지능과 블록체인 기술의 힘을 활용하여 선도적인 스마트 홈 생태계를 구축합니다. 이 시스템에서 인공지능은 뇌로 작용하고 블록체인은 프레임워크로 작용하여 스마트 홈의 발전에 더 많은 가능성과 활력을 제공합니다.'
  },
  technologyPage: {
    title: '기술',
    text1: 'WEB3+알 인텔리전트 시스템',
    text2: '관심사 및 개인 정보 인식 기술',
    text3: '분산 프로토콜 콘솔',
    text4: '통합 분산 통신 프로토콜',
    text5: '안전한 데이터 스토리지',
    text6: '지능형 네트워크 아키텍처'
  },
  freeMintPage: {
    title: 'AI + 홈 솔루션',
    content1: '저희와 함께 탈중앙화된 신원을 무료로 발급받아 안전한 비공개 디지털 신원을 경험해 보세요.',
    content2: '지금 가입하여 신원 인증의 미래를 살펴보세요!',
    comprehensive: '종합적인',
    smarthome: '스마트 홈',
    products: '서비스',
    desc: '도무스알은 인공지능과 빅데이터를 활용해 개인 맞춤형 스마트홈 서비스를 제공합니다. 사용자의 습관과 선호도를 분석하여 스마트 홈 기기의 설정과 모드를 지능적으로 조정하여 맞춤형 스마트 생활 경험을 제공합니다.'
  },
  ecosystem: '에코시스템',
  foot: '예술적 홈 리빙의 새로운 시대를 선도하다',
  pageDID: {
    mint: '지갑 연결',
    welcome: '에 오신 것을 환영합니다.',
    content1: '예술적 홈 시대의 새로운 내비게이터, ',
    content2: '집안 전체가 웹3 스마트 시스템!。',
    content3: 'DomusAl DlD 민트에 참여하여 차세대 Web3 스마트 홈 기술을 전 세계에 전파하여 총',
    content4: '5억 달러',
    content5: '규모의 보상 풀',
    freeMint: '무료 민트',
    text1: '당신의 DomusAI DID',
    FrennMint: '무료 민트',
    price: '가격',
    free: '무료!',
    amount: '금액',
    introduction: {
      title: 'DID 소개',
      content:
        'DomusAI DID의 정식 명칭은 스마트 홈 AI 시스템을 위해 특별히 설계된 혁신적인 개념인 분산형 홈 ID로, 고유한 신원 확인 솔루션을 제공합니다. 이 분산형 식별 기술은 모든 스마트 홈 시스템에 고유한 디지털 ID를 할당하여 기기 간의 상호 운용성, 보안 및 개인화된 서비스 제공을 향상시킵니다.',
      subtitle: 'Home DID의 핵심 기능',
      list: {
        title1: '고유성',
        desc1:
          '각 스마트 홈 시스템은 홈 DID를 통해 고유 식별자를 획득하여 전체 에코시스템 내에서 데이터 상호 작용 및 서비스 제공 중에 명확하고 안전한 신원을 보장합니다.',
        title2: '보안',
        desc2:
          '강력한 암호화 기술로 사용자의 개인정보와 데이터 보안을 보호하여 무단 액세스 및 데이터 침해를 방지합니다.',
        title3: '탈중앙화',
        desc3: `블록체인 기술을 활용하는 Home DID는 중앙 집중식 관리 주체에 구애받지 않고 신원 데이터의 불변성과 투명성을 보장합니다.`,
        title4: '상호 운용성',
        desc4:
          '홈 DID는 다양한 스마트 홈 디바이스와 서비스 간의 원활한 통합을 지원하여 디바이스 통합 및 관리를 간소화하고 사용자에게 보다 원활하고 개인화된 경험을 제공합니다.'
      },
      content2:
        '홈 DID를 활용하면 스마트 홈 시스템은 신원 확인과 데이터 교환을 보다 효율적으로 관리하고 홈 보안, 에너지 관리, 지능형 생활에 더욱 강력하고 유연한 지원을 제공할 수 있습니다. 음성 어시스턴트를 통해 조명을 제어하거나 고도로 개인화된 집안 환경을 조정하는 등 홈 DID는 모든 작업의 보안과 편의성을 보장하여 진정한 스마트 리빙 경험을 가능하게 합니다.'
    },
    contribution: {
      title: '나의 기여'
    },
    benefits: {
      title: 'DlD 혜택',
      subtitle: 'DomusAI DID 사용자 혜택은 다음과 같습니다:',
      content: `이러한 혜택은 사용자의 스마트홈 경험을 향상시킬 뿐만 아니라 시스템의 보안과 신뢰성을 강화하여 사용자가 스마트홈 시스템을 더욱 신뢰하고 의지할 수 있도록 합니다. 이 신원 확인 시스템의 도입은 사용자에게 더욱 안전하고 편리하며 지능적인 생활 환경을 조성하는 것을 목표로 하는 스마트 홈 기술의 미래 방향을 의미합니다.`,
      list: {
        title1: '강화된 데이터 프라이버시 및 보안',
        desc1:
          'DomusAI DID 신원 확인 시스템을 통해 사용자는 데이터 개인정보 보호 및 보안을 효과적으로 보호하여 데이터 유출 및 신원 도용의 위험을 줄일 수 있습니다.',
        title2: '원활한 교차 디바이스 관리',
        desc2:
          'DomusAI DID를 사용하면 하나의 플랫폼에서 모든 스마트 홈 장치를 관리할 수 있으므로 각 장치마다 다른 보안 프로토콜을 설정하고 기억할 필요가 없으므로 운영 편의성과 효율성이 향상됩니다.',
        title3: '개인화된 사용자 경험',
        desc3:
          'DomusAI DID는 고도로 개인화된 서비스 구성을 지원하여 사용자의 선호도와 습관에 따라 스마트홈 환경(예: 온도, 조명, 보안 설정)을 자동으로 조정하여 집안의 편안함을 향상시킵니다.',
        title4: '액세스 제어 및 권한 관리',
        desc4:
          '사용자는 DomusAI DID를 통해 다양한 접근 권한을 설정하여 가족 구성원이나 게스트의 특정 기기에 대한 접근 권한을 제어하여 집안 보안을 보장할 수 있습니다.',
        title5: '신속한 대응 및 원격 제어',
        desc5:
          '사용자는 무인 가전제품을 끄거나 집안의 온도를 조절하는 등 필요할 때마다 DomusAI DID를 통해 집안의 스마트 기기를 원격으로 제어할 수 있으며, 실시간 응답을 제공합니다.',
        title6: '우선 고객 지원',
        desc6:
          'DomusAI DID 사용자는 연중무휴 기술 지원 및 기기 오작동에 대한 신속한 대응을 포함한 우선 고객 지원 서비스를 누릴 수 있습니다.',
        title7: '독점 이벤트 및 혜택',
        desc7:
          '정기적인 독점 이벤트, 제품 업데이트 조기 액세스, 특별 할인 혜택이 DomusAI DID 사용자에게 제공되어 충성도와 만족도를 높입니다.'
      }
    }
  },
  congratulations: '축하합니다！',
  modal1: 'DomusAI DID ',
  modal2: '개를 획득했습니다',
  claim: '클레임'
}
