import { Box, Stack } from '@mui/material'
import Image from 'components/Image'
import About2 from './About2'
import useBreakpoint from 'hooks/useBreakpoint'
import FreeMint from './FreeMint'
import Ecosystem from './Ecosystem'
import Button from 'components/Button/Button'
import bgTop from 'assets/images/bg-top.png'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useI18n } from 'react-simple-i18n'
export interface visionProp {
  icon: string
  title: string
  desc: string
}

interface infoProp {
  name: string
  data: string
}

const infoList: infoProp[] = [
  {
    name: 'DIDowners',
    data: '130,257'
  },
  {
    name: 'ActiveUsers',
    data: '30,000+'
  },
  {
    name: 'SmarthomeInstalled',
    data: '504+'
  }
]

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t, i18n } = useI18n()
  const navigate = useNavigate()

  const isSmDown = useBreakpoint('sm')
  return (
    <Box
      sx={{
        background: '#000',
        position: 'relative',
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        fontSize: 12,
        padding: isSmDown ? '0 0 36px 0' : '0 0 60px 0',
        overflow: 'hidden'
      }}
    >
      <Box
        width={isSmDown ? '100%' : { xl: 1440, lg: 1292 }}
        height={isSmDown ? 'auto' : 754}
        margin={'0 auto'}
        mb={isSmDown ? 0 : 90}
        sx={{
          position: 'relative',
          zIndex: 0
        }}
      >
        {!isSmDown && (
          <Box
            position={'absolute'}
            top={0}
            left={'50%'}
            sx={{
              transform: 'translate(-50%)',
              zIndex: 99
            }}
          >
            <Image width={'553px'} src={bgTop}></Image>
          </Box>
        )}
        {!isSmDown && (
          <Box
            sx={{
              position: 'absolute',
              right: '0',
              top: 75,
              height: 674,
              opacity: 0.6,
              display: 'flex',
              'flex-direction': 'row-reverse'
            }}
          >
            <video width={'932px'} loop autoPlay muted>
              <source src={'https://zgyz.oss-cn-hangzhou.aliyuncs.com/bg.mp4'} type="video/mp4" />
            </video>
          </Box>
        )}
        {isSmDown ? (
          // mobile
          <Box
            sx={{
              color: '#fff',
              textAlign: 'left',
              position: 'relative',
              margin: '140px 24px 0'
            }}
          >
            <Box zIndex={4} fontWeight={600}>
              <Box
                sx={{
                  fontSize: 52,
                  fontWeight: 600,
                  lineHeight: '78px',
                  background: 'linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent'
                }}
              >
                Domus AI
              </Box>
              <Box
                sx={{
                  fontSize: 24,
                  lineHeight: '36px',
                  fontWeight: 500,
                  marginBottom: 29,
                  marginTop: 7
                }}
              >
                {t('homePage.content1')}
              </Box>
              <Box
                sx={{
                  color: '#a2a2a2',
                  fontSize: 14,
                  lineHeight: '21px'
                }}
              >
                {t('homePage.content2')}
              </Box>
              <Box mt={50}>
                <Button
                  onClick={() => {
                    navigate('/did')
                  }}
                  backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
                  borderColor={'#1EB2D8'}
                  width={'198px'}
                  height={'50px'}
                  fontSize={20}
                >
                  {t('freeMint')}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              color: '#fff',
              width: '100%',
              textAlign: isSmDown ? 'center' : 'left',
              padding: isSmDown ? '0 15px' : '0 40px',
              fontFamily: 'Inter'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '0',
                top: '250px'
              }}
              zIndex={4}
              fontWeight={600}
            >
              <Box
                sx={{
                  fontSize: 72,
                  fontWeight: 600,
                  lineHeight: '108px',
                  background: 'linear-gradient(90deg, #52FFD2 0%, #1FB3D9 100%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent'
                }}
              >
                Domus AI
              </Box>
              <Box
                sx={{
                  fontSize: 32,
                  lineHeight: '48px',
                  fontWeight: 500,
                  marginBottom: 29,
                  marginTop: 7
                }}
              >
                {t('homePage.content1')}
              </Box>
              <Box
                sx={{
                  color: '#a2a2a2',
                  fontSize: 18,
                  lineHeight: '27px',
                  maxWidth: 635
                }}
              >
                {t('homePage.content2')}
              </Box>
              <Box mt={50}>
                <Button
                  onClick={() => {
                    navigate('/did')
                  }}
                  backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
                  borderColor={'#1EB2D8'}
                  width={isSmDown ? '100%' : '198px'}
                  height={isSmDown ? '40px' : '50px'}
                  fontSize={20}
                >
                  {t('freeMint')}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isSmDown && (
        <Box mt={20}>
          <video width={'100%'} loop autoPlay muted>
            <source src={'https://zgyz.oss-cn-hangzhou.aliyuncs.com/bg.mp4'} type="video/mp4" />
          </video>
        </Box>
      )}

      <Stack
        direction={'row'}
        spacing={isSmDown ? 20 : 100}
        justifyContent={'center'}
        sx={{
          height: 88,
          backgroundColor: '#fff'
        }}
      >
        {infoList.map((item: infoProp) => {
          return (
            <Stack
              justifyContent={'center'}
              direction={isSmDown ? 'column' : 'row'}
              alignItems={'center'}
              key={item.name}
              spacing={isSmDown ? 0 : 20}
            >
              <Box
                sx={{
                  lineHeight: '27px',
                  fontSize: isSmDown ? 18 : 32,
                  fontWeight: isSmDown ? 600 : 500
                }}
              >
                {item.data}
              </Box>
              <Box
                sx={{
                  lineHeight: '21px',
                  fontSize: isSmDown ? (i18n.getLang() === 'JP' ? 10 : 14) : 18,
                  fontWeight: isSmDown ? 600 : 500,
                  background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
                  '-webkit-background-clip': 'text',
                  '-webkit-text-fill-color': 'transparent'
                }}
              >
                {t(item.name)}
              </Box>
            </Stack>
          )
        })}
      </Stack>
      <About2 />
      <FreeMint />
      <Ecosystem />
    </Box>
  )
}
