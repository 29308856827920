import { useMemo } from 'react'
import { useTheme, Box, Typography, styled } from '@mui/material'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import WalletModal from 'components/Modal/WalletModal/index'
import Spinner from 'components/Spinner'
// import Button from 'components/Button/Button'
import useBreakpoint from 'hooks/useBreakpoint'
import useModal from 'hooks/useModal'
import { useI18n } from 'react-simple-i18n'
import { useActiveWeb3React } from 'hooks'

const LangsBox = styled(Box)(() => ({
  borderRadius: '50px',
  border: '3px solid #52FFD2',
  color: '#fff',
  fontSize: 20,
  padding: '0 60px',
  height: 50,
  lineHeight: '44px',
  cursor: 'pointer',
  '--mask-bg': 'linear-gradient(#fff, #000)'
}))

const MLangsBox = styled(Box)(() => ({
  borderRadius: '50px',
  border: '3px solid #52FFD2',
  color: '#fff',
  fontSize: 14,
  height: 32,
  lineHeight: '26px',
  cursor: 'pointer'
}))

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner({ IsNftPage }: { IsNftPage: boolean }) {
  const { account, chainId, errorNetwork } = useActiveWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const allTransactions = useAllTransactions()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter(tx => !tx.receipt && tx.from === account).map(tx => tx.hash)
  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()
  const theme = useTheme()
  const { hideModal } = useModal()
  const isDownSm = useBreakpoint()
  const { t } = useI18n()
  if (account && chainId) {
    return (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: { sm: 'flex', xs: 'grid' },
            gridTemplateColumns: 'auto auto',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <div /> */}
          {hasPendingTransactions ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: { xs: 10, sm: 17 }, ml: { xs: 10, sm: 20 } }}>
              <Spinner color={IsNftPage ? '#fff' : theme.palette.text.primary} size={isDownSm ? '10px' : '16px'} />
              <Box component="span" sx={{ ml: 3 }}>
                <Typography sx={{ fontSize: 16, ml: 8, color: IsNftPage ? '#fff' : theme.palette.text.primary }} noWrap>
                  {pending?.length} Pending
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <LangsBox
                onClick={() => {
                  hideModal()
                  toggleWalletModal()
                }}
              >
                {ENSName || shortenAddress(account, isDownSm ? 3 : 4)}
              </LangsBox>
            </>
          )}
        </Box>
      </Box>
    )
  } else if (errorNetwork) {
    return (
      <LangsBox
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        {'Wrong'}
      </LangsBox>
    )
  } else {
    return (
      <LangsBox
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        {t('SignUp')}
      </LangsBox>
    )
  }
}

function MWeb3StatusInner({ IsNftPage }: { IsNftPage: boolean }) {
  const { account, chainId, errorNetwork } = useActiveWeb3React()
  const { ENSName } = useENSName(account ?? undefined)
  const allTransactions = useAllTransactions()
  const { i18n } = useI18n()
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])
  const pending = sortedRecentTransactions.filter(tx => !tx.receipt && tx.from === account).map(tx => tx.hash)
  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()
  const theme = useTheme()
  const { hideModal } = useModal()
  const isDownSm = useBreakpoint()
  const { t } = useI18n()
  if (account && chainId) {
    return (
      <Box
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: { sm: 'flex', xs: 'grid' },
            gridTemplateColumns: 'auto auto',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <div /> */}
          {hasPendingTransactions ? (
            <Box sx={{ display: 'flex', alignItems: 'center', mr: { xs: 10, sm: 17 }, ml: { xs: 10, sm: 20 } }}>
              <Spinner color={IsNftPage ? '#fff' : theme.palette.text.primary} size={isDownSm ? '10px' : '16px'} />
              <Box component="span" sx={{ ml: 3 }}>
                <Typography sx={{ fontSize: 16, ml: 8, color: IsNftPage ? '#fff' : theme.palette.text.primary }} noWrap>
                  {pending?.length} Pending
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <MLangsBox
                onClick={() => {
                  hideModal()
                  toggleWalletModal()
                }}
                padding={i18n.getLang() === 'EN' ? '0 30px' : ' 0 10px'}
              >
                {ENSName || shortenAddress(account, isDownSm ? 3 : 4)}
              </MLangsBox>
            </>
          )}
        </Box>
      </Box>
    )
  } else if (errorNetwork) {
    return (
      <MLangsBox
        padding={i18n.getLang() === 'en' ? '0 30px' : ' 0 10px'}
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        {'Wrong'}
      </MLangsBox>
    )
  } else {
    return (
      <MLangsBox
        padding={i18n.getLang() === 'en' ? '0 30px' : ' 0 10px'}
        onClick={() => {
          hideModal()
          toggleWalletModal()
        }}
      >
        {t('SignUp')}
      </MLangsBox>
    )
  }
}

export default function Web3Status({ IsNftPage, isMobile }: { IsNftPage: boolean; isMobile?: boolean }) {
  return (
    <>
      {isMobile ? <MWeb3StatusInner IsNftPage={IsNftPage} /> : <Web3StatusInner IsNftPage={IsNftPage} />}
      <WalletModal />
    </>
  )
}
