import { useDomDidContract } from './useContract'
import { useCallback } from 'react'
import { useActiveWeb3React } from './index'
import { useSingleCallResult } from '../state/multicall/hooks'
import { TransactionResponse } from '@ethersproject/providers'
export function useDomDid() {
  const { account } = useActiveWeb3React()
  const contract = useDomDidContract()
  const mintedCount = useSingleCallResult(contract, 'tokensOfOwner', [account ?? undefined])?.result
  const mint = useCallback(
    async (count: string | number) => {
      console.log(contract, 'contract')

      if (!account) throw new Error('none account')
      if (!contract) throw new Error('none contract')
      const args = [
        account,
        count,
        '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
        0,
        [[], 0, 0, '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'],
        []
      ]
      const method = 'claim'
      console.log('🚀', args, method)

      return contract[method](...args, {
        from: account
      }).then((response: TransactionResponse) => {
        return response.hash
      })
      // })
    },
    [account, contract]
  )

  return {
    mint,
    mintedCount: mintedCount && mintedCount[0] ? mintedCount[0] : []
  }
}
