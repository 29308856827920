export const EN = {
  home: 'Home',
  about: 'About Us',
  technology: 'Technology',
  products: 'Products',
  SignUp: 'Sign Up',
  connect: 'Connect Wallet',
  freeMint: 'Free Mint',
  DIDowners: 'DID owners',
  ActiveUsers: 'Active Users',
  SmarthomeInstalled: 'Smarthome Installed',
  homePage: {
    content1: 'A Comprehensive Web3 Smart Homesystem',
    content2:
      'DomusAI is an Al-driven, globally implemented new experience. We truly bring the scenarios built in the Web3 world into thousands of households around the globe.'
  },
  aboutPage: {
    content1:
      'From Startup to Industry Leader- DomusAl has been at the forefront of innovation in the smart home industry.',
    content2:
      'Committed to technological innovation, we enhance global living standards in safety and quality of life. Our team, composed of top engineers, designers, and thought leaders, is the cornerstone of our success.',
    title1: 'Main Features',
    desc1:
      'Security Meets Intelligence-DomusAl integrates Web3 technology to safeguard your data from breaches, while our Al systems learn your lifestyle habits to automatically adjust home devices, catering to your personalized needs.',
    title2: 'Driving the Future with Technology',
    desc2:
      'DomusAl harnesses the power of artificial intelligence and blockchain technology to build a leading smart home ecosystem. In this system, artificial intelligence serves as the brain, while blockchain acts as the framework, providing more possibilities and vitality for the development of smart homes.'
  },
  technologyPage: {
    title: 'Technology',
    text1: 'WEB3+Al Smart System',
    text2: 'Interest and privacy identification technology',
    text3: 'Decentralized Protocol Console',
    text4: 'Unified Distributed Communication protoco',
    text5: 'Data Security Storage',
    text6: 'Smart Network Architecture'
  },
  freeMintPage: {
    title: 'AI Solutions',
    content1:
      'Join us and mint your decentralized identity for free to experience a secure and private digital identity.',
    content2: 'Sign up now to explore the future of identity authentication!',
    comprehensive: 'Comprehensive',
    smarthome: 'Smart Home',
    products: 'Products',
    desc: 'DomusAl utilizes artificial intelligence andbig data to deliver personalized smart homeservices.By analyzing user habits andpreferences, it intelligently adapts thesettings and modes of smarthome devicesfor a tailored and intelligent livingexperience.'
  },
  ecosystem: 'Ecosystem',
  foot: 'Leading the New Era of Artistic Home Living',
  pageDID: {
    mint: 'Mint',
    welcome: 'Welcome to',
    content1: 'Whole-house Web3 smart system,',
    content2: 'the new navigator of the artistic home era!',
    content3:
      'Participate in the DomusAI DID mint and spread the next generation of Web3 smart home technology to the world, sharing a total of ',
    content4: '$500 Million',
    content5: 'DOM Reward Pool',
    freeMint: 'FREE MINT',
    text1: 'Your DomusAI DID',
    FrennMint: 'Free Mint',
    price: 'Price',
    free: 'FREE!',
    amount: 'Amount',
    introduction: {
      title: 'DID Introduction',
      content:
        'The full name of DomusAI DID is Decentralized Home ID, a revolutionary concept specifically designed for smart home AI systems, offering a unique identity verification solution. This decentralized identification technology assigns a unique digital identity to every smart home system, enhancing interoperability, security, and the delivery of personalized services between devices.',
      subtitle: 'Core Features of Home DID',
      list: {
        title1: 'Uniqueness',
        desc1:
          'Each smart home system obtains a unique identifier through Home DID, ensuring clear and secure identity during data interactions and service provision within the entire ecosystem.',
        title2: 'Decentralization',
        desc2:
          'Utilizing blockchain technology, Home DID ensures the immutability and transparency of identity data, independent of any centralized management entity.',
        title3: 'Security',
        desc3: `Strong encryption technologies protect users' privacy and data security, preventing unauthorized access and data breaches.`,
        title4: 'Interoperability',
        desc4:
          'Home DID supports seamless integration between various smart home devices and services, simplifying device integration and management, and providing users with a smoother and more personalized experience.'
      },
      content2:
        'By leveraging Home DID, smart home systems can manage identity verification and data exchange more efficiently, and provide stronger and more flexible support for home security, energy management, and intelligent living. Whether controlling lighting through a voice assistant or achieving highly personalized home environment adjustments, Home DID ensures the security and convenience of all operations, truly enabling a seamless smart living experience.'
    },
    contribution: {
      title: 'My Contribution'
    },
    benefits: {
      title: 'DID Benefits',
      subtitle: 'DomusAI DID user benefits include:',
      content: `These benefits not only enhance the user's smart home experience but also strengthen the system's security and
        reliability, making users more confident and dependent on their smart home system. The introduction of this
        identity verification system signifies the future direction of smart home technology, aiming to create a more
        secure, convenient, and intelligent living environment for users.`,
      list: {
        title1: 'Enhanced Data Privacy and Security',
        desc1:
          'Through the DomusAI DID identity verification system, users can ensure their data privacy and security are effectively protected, reducing the risk of data breaches and identity theft.',
        title2: 'Seamless Cross-Device Management',
        desc2:
          'With DomusAI DID, users can manage all their smart home devices from one platform, eliminating the need to set up and remember different security protocols for each device, thus enhancing operational convenience and efficiency.',
        title3: 'Personalized User Experience',
        desc3:
          'DomusAI DID supports highly personalized service configurations, automatically adjusting the smart home environment (such as temperature, lighting, and security settings) based on user preferences and habits to enhance home comfort.',
        title4: 'Access Control and Permission Management',
        desc4:
          'Users can set different access permissions through DomusAI DID, controlling access rights for family members or guests to certain devices to ensure home security.',
        title5: 'Rapid Response and Remote Control',
        desc5:
          'Users can remotely control smart devices at home whenever needed, whether turning off unattended appliances or adjusting the temperature at home, DomusAI DID provides real-time responses.',
        title6: 'Priority Customer Support',
        desc6:
          'DomusAI DID users can enjoy priority customer support services, including 24/7 technical support and rapid response to device malfunctions.',
        title7: 'Exclusive Events and Offers',
        desc7:
          'Regular exclusive events, early access to product updates, and special discounts are provided for DomusAI DID users, increasing their loyalty and satisfaction.'
      }
    }
  },
  congratulations: 'Congratulations!',
  modal1: 'You got',
  modal2: ' DomusAI DID',
  claim: 'Succeed'
}
