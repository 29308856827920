import { Box, Stack } from '@mui/material'
import { useCallback, useState, useEffect } from 'react'
import { useActiveWeb3React } from '../../hooks'
import Image from 'components/Image'
import useBreakpoint from 'hooks/useBreakpoint'
import Button from 'components/Button/Button'
import didBg from 'assets/images/did_bg.png'
import { useDomDid } from '../../hooks/useDomDid'
import MessageBox from '../../components/Modal/TransactionModals/MessageBox'
import TransactionPendingModal from 'components/Modal/TransactionModals/TransactionPendingModal'
import useModal from '../../hooks/useModal'
import { useWalletModalToggle } from 'state/application/hooks'
import SucceedModal from 'components/Modal/Succeed/index'
import sIcon1 from 'assets/images/s_icon_1.png'
import sIcon2 from 'assets/images/s_icon_2.png'
import sIcon3 from 'assets/images/s_icon_3.png'
import sIcon4 from 'assets/images/s_icon_4.png'
import sIcon5 from 'assets/images/s_icon_5.png'
import sIcon6 from 'assets/images/s_icon_6.png'
import sIcon7 from 'assets/images/s_icon_7.png'
import giftImg from 'assets/images/gift_img.png'
import didLeft from 'assets/images/did_left.png'
import didRight from 'assets/images/did_right.png'
import { useI18n } from 'react-simple-i18n'
import IsolationMode from 'assets/images/Isolation_mode.png'
import didNftLogo from 'assets/images/did_nft_logo_v2.png'
import { BTabs } from 'components/Tabs/Tabs'

export interface visionProp {
  icon: string
  title: string
  desc: string
}

interface ArticleProps {
  title: string
  content: string
}

interface IconArticleList extends ArticleProps {
  icon: string
}

const DIDIntroduction = () => {
  const { t } = useI18n()
  const DIDIntroductionArticleList = [
    {
      title: t('pageDID.introduction.list.title1'),
      content: t('pageDID.introduction.list.desc1')
    },
    {
      title: t('pageDID.introduction.list.title2'),
      content: t('pageDID.introduction.list.desc2')
    },
    {
      title: t('pageDID.introduction.list.title3'),
      content: t('pageDID.introduction.list.desc3')
    },
    {
      title: t('pageDID.introduction.list.title4'),
      content: t('pageDID.introduction.list.desc4')
    }
  ]
  return (
    <Box padding={'18px 0'}>
      <Stack alignItems={'center'} justifyContent={'center'} mt={72} direction={'row'}>
        <Box mr={115} width={387}>
          <Image width={'100%'} src={IsolationMode}></Image>
        </Box>
        <Box
          width={573}
          sx={{
            fontSize: 18,
            fontWeight: 400,
            lineHeight: '27px',
            color: '#fff'
          }}
        >
          {t('pageDID.introduction.content')}
        </Box>
      </Stack>
      <Box
        sx={{
          borderRadius: '20px',
          padding: '40px 20px 12px',
          background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)'
        }}
      >
        <Box
          fontSize={36}
          lineHeight={'54px'}
          fontWeight={600}
          mb={40}
          textAlign={'center'}
          sx={{
            background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent'
          }}
        >
          {t('pageDID.introduction.subtitle')}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {DIDIntroductionArticleList.map((item, idx) => {
            return (
              <Box mt={36} padding={'0 20px'} width={'50%'} mb={28} key={idx}>
                <SectionArticle title={item.title} content={item.content} />
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box
        textAlign={'center'}
        margin={'0 auto'}
        width={892}
        mt={50}
        fontSize={18}
        lineHeight={'27px'}
        fontWeight={400}
        color={'#fff'}
      >
        {t('pageDID.introduction.content2')}
      </Box>
    </Box>
  )
}

const DidNft = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
        border: '2px solid #3F3F3F 100%',
        borderRadius: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      width={250}
      height={250}
    >
      <Image width={150} height={150} src={didNftLogo}></Image>
    </Box>
  )
}

const MyContribution = (mintedCount: any) => {
  console.log(mintedCount, 'mintedCount')

  if (mintedCount && mintedCount.length > 0) {
    return (
      <Box
        pb={18}
        pt={54}
        sx={{
          display: 'grid',
          'grid-gap': '100px',
          'grid-template-columns': '250px 250px 250px',
          'grid-template-rows': '286px',
          'justify-content': 'center'
        }}
        mt={18}
      >
        {mintedCount &&
          mintedCount.map((item: any) => {
            return (
              <Box textAlign={'center'} width={250} key={item}>
                <DidNft />
                <Box
                  sx={{
                    fontSize: 24,
                    lineHeight: '36px',
                    color: '#fff'
                  }}
                >
                  I own : {item?.toString()}
                </Box>
              </Box>
            )
          })}
      </Box>
    )
  }
  return <></>
}

const DIDBenefits = () => {
  const { t } = useI18n()
  const DIDBenefitsArticleList = [
    {
      title: t('pageDID.benefits.list.title1'),
      icon: sIcon1,
      content: t('pageDID.benefits.list.desc1')
    },
    {
      title: t('pageDID.benefits.list.title2'),
      icon: sIcon2,
      content: t('pageDID.benefits.list.desc2')
    },
    {
      title: t('pageDID.benefits.list.title3'),
      icon: sIcon3,
      content: t('pageDID.benefits.list.desc3')
    },
    {
      title: t('pageDID.benefits.list.title4'),
      icon: sIcon4,
      content: t('pageDID.benefits.list.desc4')
    },
    {
      title: t('pageDID.benefits.list.title5'),
      icon: sIcon5,
      content: t('pageDID.benefits.list.desc5')
    },
    {
      title: t('pageDID.benefits.list.title6'),
      icon: sIcon6,
      content: t('pageDID.benefits.list.desc6')
    },
    {
      title: t('pageDID.benefits.list.title7'),
      icon: sIcon7,
      content: t('pageDID.benefits.list.desc7')
    }
  ]
  return (
    <Box padding={'18px 0'}>
      <Box textAlign={'center'} mb={50} fontSize={24} lineHeight={'36px'} fontWeight={400} color={'#fff'}>
        {t('pageDID.benefits.subtitle')}
      </Box>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        sx={{
          flexWrap: 'wrap'
        }}
      >
        {DIDBenefitsArticleList.map((item, idx) => {
          return (
            <Box mb={20} width={'calc(50% - 10px)'} key={idx}>
              <SectionIconArticle title={item.title} icon={item.icon} content={item.content} />
            </Box>
          )
        })}
      </Stack>
      <Box
        margin={'0 auto'}
        textAlign={'center'}
        width={994}
        mt={24}
        fontSize={18}
        lineHeight={'27px'}
        fontWeight={400}
        color={'#fff'}
      >
        {t('pageDID.benefits.content')}
      </Box>
    </Box>
  )
}
const SectionArticle = (props: ArticleProps) => {
  const { title, content } = props

  return (
    <Box textAlign={'center'}>
      <Box
        fontSize={24}
        lineHeight={'36px'}
        fontWeight={700}
        mb={3}
        sx={{
          background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent'
        }}
      >
        {title}
      </Box>
      <Box mt={3} fontSize={18} lineHeight={'27px'} fontWeight={400} color={'#fff'}>
        {content}
      </Box>
    </Box>
  )
}
const SectionIconArticle = (props: IconArticleList) => {
  const { title, content, icon } = props

  return (
    <Box
      padding={'20px 10px'}
      height={'100%'}
      borderRadius={'20px'}
      sx={{
        background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)'
      }}
    >
      <Box textAlign={'center'}>
        <Image src={icon}></Image>
      </Box>
      <SectionArticle title={title} content={content} />
    </Box>
  )
}
export default function Did() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { mint, mintedCount } = useDomDid()
  const { t } = useI18n()
  const { showModal, hideModal } = useModal()
  const { account } = useActiveWeb3React()
  const toggleWalletModal = useWalletModalToggle()
  const [count, setCount] = useState(1)
  const mintCallback = useCallback(async () => {
    if (!account || !count) return
    showModal(<TransactionPendingModal />)
    mint(count)
      .then(() => {
        hideModal()
        showModal(<SucceedModal num={count} />)
      })
      .catch((err: any) => {
        hideModal()
        showModal(
          <MessageBox type="error">{err.error && err.error.message ? err.error.message : err?.message}</MessageBox>
        )
        console.error(err)
      })
  }, [account, count, showModal, mint, hideModal])

  function NFTButtomFun() {
    if (!account) {
      return (
        <>
          <Button
            onClick={toggleWalletModal}
            backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
            borderColor={'#1EB2D8'}
            width={'100%'}
            height={'42px'}
            fontSize={20}
          >
            {t('connect')}
          </Button>
        </>
      )
    }
    return (
      <>
        <Button
          onClick={mintCallback}
          backgroundColor={'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)'}
          borderColor={'#1EB2D8'}
          width={'100%'}
          height={'42px'}
          fontSize={20}
        >
          {t('pageDID.mint')}
        </Button>
      </>
    )
  }
  const isSmDown = useBreakpoint('sm')
  // }
  return (
    <Box
      pb={58}
      sx={{
        background: '#000'
      }}
    >
      <Box
        sx={{
          background: '#000',
          position: 'relative',
          width: '100%',
          margin: '0 auto',
          textAlign: 'center',
          fontSize: 12,
          padding: isSmDown ? '0 0 36px 0' : '0 0 60px 0',
          overflow: 'hidden'
        }}
      >
        <Box
          width={isSmDown ? '100%' : { xl: 1440, lg: 1292 }}
          height={{ xl: 1040, lg: 800 }}
          margin={'0 auto'}
          mb={isSmDown ? 0 : 90}
          sx={{
            position: 'relative',
            zIndex: 1
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              left: '50%',
              top: { xl: '50%', lg: '60%' },
              transform: 'translate(-50%,-50%)'
            }}
          >
            <Box
              sx={{
                fontSize: 32,
                lineHeight: '48px',
                fontWeight: 600,
                background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }}
            >
              {t('pageDID.welcome')}
            </Box>
            <Box
              sx={{
                fontSize: 104,
                lineHeight: '156px',
                fontWeight: 600,
                background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent'
              }}
            >
              Domus AI
            </Box>
            <Box
              sx={{
                fontSize: 24,
                lineHeight: '36px',
                fontWeight: 600,
                color: '#fff'
              }}
            >
              {t('pageDID.content1')}
            </Box>
            <Box
              sx={{
                fontSize: 24,
                lineHeight: '36px',
                fontWeight: 600,
                color: '#fff'
              }}
            >
              {t('pageDID.content2')}
            </Box>
            <Box
              margin={'0 auto'}
              mt={76}
              width={828}
              height={239}
              sx={{
                background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
                padding: '30px 30px 0 30px',
                borderRadius: '20px',
                border: '1px solid #1EB2D8'
              }}
            >
              <Box
                sx={{
                  color: '#fff',
                  fontSize: 24,
                  lineHeight: '36px',
                  fontWeight: 600,
                  margin: '0 auto'
                }}
                width={630}
              >
                {t('pageDID.content3')}
              </Box>
              <Stack direction={'row'} justifyContent={'center'}>
                <Box
                  mr={20}
                  sx={{
                    fontSize: 46,
                    lineHeight: '69px',
                    fontWeight: 600,
                    background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent'
                  }}
                >
                  {t('pageDID.content4')}
                </Box>
                <Box
                  sx={{
                    fontSize: 24,
                    lineHeight: '88px',
                    fontWeight: 600,
                    background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent'
                  }}
                >
                  {t('pageDID.content5')}
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0
          }}
        >
          <Image width={'100%'} src={didBg}></Image>
          <Box
            sx={{
              position: 'absolute',
              left: '0',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1
            }}
          >
            <Image src={didLeft}></Image>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%)',
              zIndex: 1
            }}
          >
            <Image src={didRight}></Image>
          </Box>
        </Box>
      </Box>
      <Box
        width={1292}
        margin={'0 auto'}
        mt={80}
        mb={80}
        display={'flex'}
        sx={{
          padding: '50px 25px',
          background: 'linear-gradient(180deg, #0C0C0C 0%, #242424 100%)',
          borderRadius: '20px',
          textAlign: 'left'
        }}
      >
        <Box position={'relative'} height={'373px'} width={'50%'}>
          <Box
            sx={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translate(-15%, -50%)'
            }}
          >
            <Image src={giftImg}></Image>
          </Box>
        </Box>
        <Box width={'50%'}>
          <Box
            sx={{
              fontSize: 24,
              lineHeight: '36px',
              fontWeight: 500,
              background: 'linear-gradient(135deg, #1EB2D8 25%, #52FFD2 147.02%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('pageDID.freeMint')}
          </Box>
          <Box
            mt={4}
            mb={53}
            sx={{
              fontSize: 36,
              lineHeight: '54px',
              fontWeight: 600,
              background: 'linear-gradient(289.82deg, #1EB2D8 5.3%, #52FFD2 58.18%)',
              '-webkit-background-clip': 'text',
              '-webkit-text-fill-color': 'transparent'
            }}
          >
            {t('pageDID.text1')}
          </Box>
          <Box display={'flex'} width={535}>
            <Box width={'50%'} fontSize={24} lineHeight={'36px'} fontWeight={400} color={'#fff'}>
              {t('pageDID.FrennMint')}
            </Box>
            <Box width={'50%'} fontSize={24} lineHeight={'36px'} fontWeight={600} color={'#fff'}>
              DomusAI DID
            </Box>
          </Box>
          <Box mt={18} mb={18} display={'flex'} width={535}>
            <Box width={'50%'} fontSize={24} lineHeight={'36px'} fontWeight={400} color={'#fff'}>
              {t('pageDID.price')}
            </Box>
            <Box width={'50%'} fontSize={24} lineHeight={'36px'} fontWeight={600} color={'#fff'}>
              {t('pageDID.free')}
            </Box>
          </Box>

          <Box display={'flex'} width={535}>
            <Box width={'50%'} fontSize={24} lineHeight={'36px'} fontWeight={400} color={'#fff'}>
              {t('pageDID.amount')}
            </Box>
            <Box width={'50%'}>
              <Stack direction={'row'}>
                <Button
                  disabled={count == 1}
                  onClick={() => setCount(count - 1)}
                  style={{
                    height: 34,
                    lineHeight: '30px',
                    background: '#000',
                    fontWeight: 600,
                    fontSize: 15,
                    textAlign: 'center',
                    cursor: 'pointer',
                    borderRadius: '20px',
                    border: '2px solid #FFFFFF',
                    color: '#fff',
                    flex: 1
                  }}
                >
                  -
                </Button>
                <Box
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    lineHeight: '34px',
                    fontWeight: 600,
                    fontSize: 18,
                    width: '95px',
                    height: 34,
                    borderRadius: '20px',
                    color: '#fff',
                    background: '#272727',
                    margin: '0 14px'
                  }}
                >
                  {count}
                </Box>
                <Button
                  onClick={() => setCount(count + 1)}
                  style={{
                    height: 34,
                    lineHeight: '30px',
                    fontWeight: 600,
                    background: '#000',
                    fontSize: 15,
                    textAlign: 'center',
                    cursor: 'pointer',
                    borderRadius: '20px',
                    border: '2px solid #FFFFFF',
                    color: '#fff',
                    flex: 1
                  }}
                >
                  +
                </Button>
              </Stack>
            </Box>
          </Box>

          <Box mt={40}>
            <NFTButtomFun />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: '#000'
        }}
        width={1292}
        margin={'0 auto'}
      >
        <BTabs
          titles={[t('pageDID.introduction.title'), t('pageDID.contribution.title'), t('pageDID.benefits.title')]}
          contents={[DIDIntroduction(), MyContribution(mintedCount), DIDBenefits()]}
        />
      </Box>
      <Box mt={58} color={'#fff'} fontSize={18} lineHeight={'27px'} textAlign={'center'}>
        © Meta Ventures International LLC . All Rights Reserved 2024.
      </Box>
    </Box>
  )
}
